// --------------------------------------------------
// @Date:   2018-10-15 09:30:52
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-05-08 10:36:58
// --------------------------------------------------

.header {
  position: relative;
  display: flex;
  flex-direction: column;

  .nav-item:hover  {
    z-index: ($zindex-sticky + 10);
  }

  .dropdown-item,
  .nav-link {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
  }

  > *:not(.bg) {
    // z-index: ($zindex-dropdown - 10);
  }
}
