// --------------------------------------------------
// @Date:   2018-10-26 08:36:58
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:44
// --------------------------------------------------

// stylelint-disable declaration-no-important

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  @each $color, $value in $c-theme-colors {
    .border-#{$color} {
      border-color: $value !important;
    }
  }
}

@each $color, $value in $grays {
  .border-gray-#{$color} {
    border-color: $value !important;
  }
}
