// --------------------------------------------------
// @Date:   2018-10-09 01:41:41
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-01-18 11:01:07
// --------------------------------------------------

.rank {
  display: block;
  width: 32px;
  height: 32px;
  // font-size: .7rem;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  // border-radius: 50%;

  &.overlay-c-c {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
  }

  &.overlay-t-r {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
  }
}
