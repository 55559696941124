// --------------------------------------------------
// @Date:   2018-09-27 19:46:59
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-08 09:26:51
// --------------------------------------------------

// stylelint-disable declaration-no-important
// stylelint-disable selector-no-qualifying-type
// stylelint-disable selector-max-compound-selectors
// stylelint-disable selector-max-class

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: ($grid-gutter-width/2);
  padding-bottom: ($grid-gutter-width/2);
  padding-left: ($grid-gutter-width/2);

  .section {
    margin-right: -($grid-gutter-width/2);
    margin-left: -($grid-gutter-width/2);
  }
  .row > & {
    margin-right: 0;
    margin-left: 0;
  }
}

.section-img {
  position: relative;
  z-index: 2;
}

.section-header {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: $section-header-pt;
  padding-bottom: $section-header-pb;
}

.section-title {
  margin: 0;
  // font-size: $font-size-base;
  font-weight: 600;
  // line-height: $section-header-line-height;
  // text-transform: uppercase;
}

.section-nav-tabs {
  border: none;
  .nav-item {
    margin-bottom: 0;
    font-size: .95rem;
  }
  .nav-link {
    padding: 0;
    border: none;
    &.active {
      background-color: transparent;
    }
    ~ .nav-link {
      margin-left: 1rem;
    }
  }
}

.section-footer {
  position: relative;
  z-index: 2;
}

.section-light {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {  // stylelint-disable-line selector-list-comma-newline-after
    &,
    a {
      color: $section-heading-color;
    }
    a {
      @include hover-focus {
        color: $section-heading-hover-color;
      }
    }
  }
  &.section--footer {
    color: $secondary;
    a {
      color: $secondary;
      @include hover-focus {
        color: $section-dark-link-hover-color;
      }
    }
  }
  > .section-header,
  .row > .section-header {
    color: $section-title-color;
    .section-title {
      color: $section-title-color;
      a {
        color: $section-title-color;
        @include hover-focus {
          color: $section-title-hover-color;
        }
      }
    }
    .nav {
      color: $section-subnav-color;
    }
    .nav-link {
      &:not(.active) {
        color: $section-subnav-color !important;
      }
      @include hover-focus {
        color: $section-subnav-hover-color;
      }
      &.active {
        color: $section-subnav-hover-color;
      }
    }
  }
}

.section-dark {
  color: $post-dark-color;

  a {
    color: $section-dark-link-color;
    @include hover-focus {
      color: $section-dark-link-hover-color;
    }
  }
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { // stylelint-disable-line selector-list-comma-newline-after
    &,
    a {
      color: $section-dark-heading-color;
    }
    a {
      @include hover-focus {
        color: $section-dark-heading-hover-color;
      }
    }
  }
  &.section--footer {
    color: $secondary;
    a {
      color: $secondary;
      @include hover-focus {
        color: $section-dark-link-hover-color;
      }
    }
  }
  > .section-header,
  .row > .section-header {
    color: $section-dark-title-color;
    .section-title {
      color: $section-dark-title-color;
      a {
        color: $section-dark-title-color;
        @include hover-focus {
          color: $section-dark-title-hover-color;
        }
      }
    }
    .nav {
      color: $section-dark-subnav-color;
    }
    .nav-link {
      &:not(.active) {
        color: $section-dark-subnav-color !important;
      }
      @include hover-focus {
        color: $section-dark-subnav-hover-color;
      }
      &.active {
        color: $section-dark-subnav-hover-color;
      }
    }
  }
}
