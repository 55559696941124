// --------------------------------------------------
// @Date:   2019-03-23 11:30:24
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-05-14 10:16:43
// --------------------------------------------------

// stylelint-disable declaration-no-important

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .carousel-control-next,
  .carousel-control-prev {
    width: $carousel-control-width;
    opacity: $carousel-control-opacity;
  }
}

.carousel-inner {
  width: auto;
  min-width: 100%;
  .section > & {
    margin-right: -($grid-gutter-width/2);
    margin-left: -($grid-gutter-width/2);
  }
}

.carousel-item {
  .section > .carousel-inner > & {
    padding-right: ($grid-gutter-width/2);
    padding-left: ($grid-gutter-width/2);
  }
}

.carousel-control-next,
.carousel-control-prev {
  top: 50%;
  z-index: 10;
  height: $carousel-control-width;
  margin-top: -($carousel-control-width/2);
  line-height: $carousel-control-icon-width;
  color: $link-color !important;
  background-color: $white;
  border: 1px solid rgba($gray-800, .1);
  .section > & {
    margin-top: -(( $carousel-control-width/2 ) + round($grid-gutter-width / 4));
  }
  .carousel:hover & {
    opacity: 1;
  }
  @include hover {
    color: $link-hover-color !important;
    border-color: $link-hover-color !important;
  }
  &:focus {
    color: $white !important;
    background-color: $primary !important;
  }
}

.carousel-control-next {
  margin-right: -($grid-gutter-width/2);
  .section > & {
    margin-right: 0;
  }
}
.carousel-control-prev {
  margin-left: -($grid-gutter-width/2);
  .section > & {
    margin-left: 0;
  }
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
}
.carousel-control-next-icon {
  &::before {
    font-family: "Font Awesome 5 Free", serif;
    font-style: normal;
    font-weight: 900;
    content: $unicode_angle_right;
  }
}
.carousel-control-prev-icon {
  &::before {
    font-family: "Font Awesome 5 Free", serif;
    font-style: normal;
    font-weight: 900;
    content: $unicode_angle_left;
  }
}

.carousel-indicators {
  .active {
    background-color: $carousel-indicator-active-only-bg;
  }
}

.carousel--post-thumbnail {
  .carousel-indicators {
    bottom: 1rem;
  }

  &.with-image-caption {
    .carousel-indicators {
      bottom: 3rem;
      padding-top: ($spacer * .5);
      padding-bottom: ($spacer * .5);
    }
  }
}
