// --------------------------------------------------
// @Date:   2018-10-17 09:14:17
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:53
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .dropdown-menu {
    margin: $dropdown-spacer 0 0; // override default ul
  }

  .dropup {
    .dropdown-menu {
      margin-bottom: $dropdown-spacer;
    }
  }

  .dropright {
    .dropdown-menu {
      margin-left: $dropdown-spacer;
    }
  }

  .dropleft {
    .dropdown-menu {
      margin-right: $dropdown-spacer;
    }
  }

  .dropdown-item {
    color: $dropdown-link-color;

    @include hover-focus {
      color: $dropdown-link-hover-color;
    }

    &.active,
    &:active {
      @include gradient-bg($dropdown-link-active-bg);
    }
  }
  .dropdown-item-text {
    color: $dropdown-link-color;
  }
}

.header {
  .dropdown-menu {
    z-index: $zindex-dropdown + ( $zindex-sticky - $zindex-dropdown + 10);
    margin-right: -($dropdown-item-padding-x - $navbar-nav-link-padding-x);
    margin-left: -($dropdown-item-padding-x - $navbar-nav-link-padding-x);
  }

  .dropdown-item {
    // color: rgba($black, .6);

    @include hover-focus {
      // color: $primary;
      background-color: transparent;
    }

    &.active,
    &:active {
      color: $primary;
      background-color: transparent;
    }
  }
}

.dropdown {
  &.allow-hover {
    @include hover-focus {
      > .dropdown-menu {
        display: block;
      }
    }
  }
}

.dropdown-item {
  margin-bottom: 0;
  .row {
    margin-right: -($dropdown-item-padding-x);
    margin-left: -($dropdown-item-padding-x);
  }
}

.dropdown--mainmenu {

  & > .dropdown-menu {
    min-width: 300px;
  }

  .navbar & > .dropdown-toggle {
    position: relative;
    z-index: $zindex-dropdown + 1;
    border: $dropdown-border-width solid transparent;
  }

  .navbar &.show > .nav-link {
    background-clip: padding-box;
  }

  /* stylelint-disable */
  > ul > li > a.dropdown-item {
    font-size: $font-size-base * 1.25;
    line-height: 1.5;
    // color: rgba($primary, .8);
  }
  /* stylelint-enable */
}

.mainmenu-close {
  position: absolute;
  top: ($grid-gutter-width/2);
  right: ($grid-gutter-width/2);
  left: auto;
  font-size: $font-size-lg;
}

.dropdown--mega {
  position: initial;

  .dropdown-menu {
    min-width: 100%;
    margin-right: 0;
    margin-left: 0;

    .container & {
      right: -($grid-gutter-width/2);
      left: -($grid-gutter-width/2);
    }
  }
}

.dropdown-toggle {
  &.disable-caret {
    &::after {
      display: none !important; /* stylelint-disable-line declaration-no-important */
      content: inherit !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.dropdown .dropdown {
  position: relative;
  & > a::after {
    float: right;
    content: "";
  }
  & > .dropdown-menu {
    top: -($dropdown-padding-y);
    left: 100%;
    margin-top: -($dropdown-border-width);
    margin-left: 0;
  }
  &:hover > .dropdown-menu {
    // display: block;
  }
}

@include media-breakpoint-down(xs) {
  .mainnav-active {
    overflow: hidden;
  }
  #header,
  #main,
  #footer,
  .section {
    min-width: 100%;
    transition: all .5s ease-out;
    .mainnav-active > & {
      margin-left: 100%;
    }
  }
  #mainNav {
    > .dropdown-menu {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -100%;
      z-index: $zindex-modal;
      display: block;
      width: 100%;
      min-width: initial;
      overflow: auto;
      transition: all .4s ease-out;
      .mainnav-active & {
        left: 0;
        transition: all .6s ease-out;
      }
    }
    .navbar-nav & .dropdown-menu.show {
      display: block;
    }
    .dropdown > .dropdown-menu {
      position: relative;
      top: initial;
      right: initial;
      bottom: initial;
      left: initial;
      margin-left: initial;
      border-right: none;
      border-bottom: none;
      border-left: none;
    }
  }
}
// @include media-breakpoint-down(xs) {
//   #header,
//   #main,
//   #footer,
//   .section {
//     .mainnav-active > & {
//       margin-left: 100%;
//     }
//   }
//   #mainNav {
//     > .dropdown-menu {
//       width: 100%;
//     }
//   }
// }
