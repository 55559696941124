// --------------------------------------------------
// @Date:   2019-05-15 22:30:59
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-05-15 22:34:45
// --------------------------------------------------

// stylelint-disable declaration-no-important

@each $value in $overflows {
  .overflow-x-#{$value} { overflow-x: $value !important; }
  .overflow-y-#{$value} { overflow-y: $value !important; }
}
