// --------------------------------------------------
// @Date:   2018-10-10 08:37:36
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-05 10:59:41
// --------------------------------------------------

// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {

      .#{$abbrev}#{$infix}-gutter-2 { #{$prop}: $grid-gutter-width !important; }
      .#{$abbrev}t#{$infix}-gutter-2,
      .#{$abbrev}y#{$infix}-gutter-2 {
        #{$prop}-top: $grid-gutter-width !important;
      }
      .#{$abbrev}r#{$infix}-gutter-2,
      .#{$abbrev}x#{$infix}-gutter-2 {
        #{$prop}-right: $grid-gutter-width !important;
      }
      .#{$abbrev}b#{$infix}-gutter-2,
      .#{$abbrev}y#{$infix}-gutter-2 {
        #{$prop}-bottom: $grid-gutter-width !important;
      }
      .#{$abbrev}l#{$infix}-gutter-2,
      .#{$abbrev}x#{$infix}-gutter-2 {
        #{$prop}-left: $grid-gutter-width !important;
      }

      .#{$abbrev}#{$infix}-gutter { #{$prop}: ($grid-gutter-width/2) !important; }
      .#{$abbrev}t#{$infix}-gutter,
      .#{$abbrev}y#{$infix}-gutter {
        #{$prop}-top: ($grid-gutter-width/2) !important;
      }
      .#{$abbrev}r#{$infix}-gutter,
      .#{$abbrev}x#{$infix}-gutter {
        #{$prop}-right: ($grid-gutter-width/2) !important;
      }
      .#{$abbrev}b#{$infix}-gutter,
      .#{$abbrev}y#{$infix}-gutter {
        #{$prop}-bottom: ($grid-gutter-width/2) !important;
      }
      .#{$abbrev}l#{$infix}-gutter,
      .#{$abbrev}x#{$infix}-gutter {
        #{$prop}-left: ($grid-gutter-width/2) !important;
      }

    }

    .m#{$infix}--gutter-2 { margin: -($grid-gutter-width) !important; }
    .mt#{$infix}--gutter-2,
    .my#{$infix}--gutter-2 {
      margin-top: -($grid-gutter-width) !important;
    }
    .mr#{$infix}--gutter-2,
    .mx#{$infix}--gutter-2 {
      margin-right: -($grid-gutter-width) !important;
    }
    .mb#{$infix}--gutter-2,
    .my#{$infix}--gutter-2 {
      margin-bottom: -($grid-gutter-width) !important;
    }
    .ml#{$infix}--gutter-2,
    .mx#{$infix}--gutter-2 {
      margin-left: -($grid-gutter-width) !important;
    }

    .m#{$infix}--gutter { margin: -($grid-gutter-width/2) !important; }
    .mt#{$infix}--gutter,
    .my#{$infix}--gutter {
      margin-top: -($grid-gutter-width/2) !important;
    }
    .mr#{$infix}--gutter,
    .mx#{$infix}--gutter {
      margin-right: -($grid-gutter-width/2) !important;
    }
    .mb#{$infix}--gutter,
    .my#{$infix}--gutter {
      margin-bottom: -($grid-gutter-width/2) !important;
    }
    .ml#{$infix}--gutter,
    .mx#{$infix}--gutter {
      margin-left: -($grid-gutter-width/2) !important;
    }

    // .mt#{$infix}-sh { margin-top: $section-header-total-h !important; }
    // .mt#{$infix}--sh { margin-top: -($section-header-total-h) !important; }

    // .pt#{$infix}-sh { padding-top: $section-header-total-h !important; }
    // .pt#{$infix}--sh { padding-top: -($section-header-total-h) !important; }

    $sidebar-w-gutter: $sidebar-w + $grid-gutter-width !default;
    .mr#{$infix}-sbar {
      margin-right: $sidebar-w-gutter !important;
    }
    .ml#{$infix}-sbar {
      margin-left: $sidebar-w-gutter !important;
    }

    $sidebar-sm-w-gutter: $sidebar-sm-w + $grid-gutter-width !default;
    .mr#{$infix}-sbar-sm {
      margin-right: $sidebar-sm-w-gutter !important;
    }
    .ml#{$infix}-sbar-sm {
      margin-left: $sidebar-sm-w-gutter !important;
    }

    // needed to reset p and m
    .m#{$infix}-0 { margin: 0 !important; }
    .mt#{$infix}-0,
    .my#{$infix}-0 {
      margin-top: 0 !important;
    }
    .mr#{$infix}-0,
    .mx#{$infix}-0 {
      margin-right: 0 !important;
    }
    .mb#{$infix}-0,
    .my#{$infix}-0 {
      margin-bottom: 0 !important;
    }
    .ml#{$infix}-0,
    .mx#{$infix}-0 {
      margin-left: 0 !important;
    }

    .p#{$infix}-0 { padding: 0 !important; }
    .pt#{$infix}-0,
    .py#{$infix}-0 {
      padding-top: 0 !important;
    }
    .pr#{$infix}-0,
    .px#{$infix}-0 {
      padding-right: 0 !important;
    }
    .pb#{$infix}-0,
    .py#{$infix}-0 {
      padding-bottom: 0 !important;
    }
    .pl#{$infix}-0,
    .px#{$infix}-0 {
      padding-left: 0 !important;
    }

  }
}
