// --------------------------------------------------
// @Date:   2018-09-30 22:50:15
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-05-20 20:31:18
// --------------------------------------------------

// stylelint-disable declaration-no-important

.bg {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  .bg-overlapped > & {
    top: $height-1;
    bottom: -($height-1);
  }

  .bg-overlappedt > & {
    top: $height-1;
  }

  .bg-overlappedb > & {
    bottom: -($height-1);
  }

  // .bg-overlapped + * > &,
  // .bg-overlapped + main > .section:first-child > & {
  //   top: ($height-1);
  // }

  .bg-overlapped-sm > & {
    top: $grid-gutter-width;
    bottom: -($grid-gutter-width);
  }

  .bg-overlappedt-sm > & {
    top: $grid-gutter-width;
  }

  .bg-overlappedb-sm > & {
    bottom: -($grid-gutter-width);
  }

  // .bg-overlapped-sm + * > &,
  // .bg-overlapped-sm + main > .section:first-child > & {
  //   top: ($height-1/4);
  // }
}

.bg-img-wrapper {
  position: relative;
  overflow: hidden;

  .bg-img {
    width: 100%;
    height: 100%;
  }
}

.bg-pull-left {
  left: auto;
  width: 100vw;
  margin-left: auto;
}

.bg-pull-right {
  right: auto;
  width: 100vw;
  margin-right: auto;
}

.bg-img {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  // transition: all .5s ease-out;

  .img {
    position: relative;
    width: 100%;
    visibility: hidden;
  }
}

.bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

[class^="pattern-"],
[class*=" pattern"] {
  background-repeat: repeat;
  background-size: initial;
}

.col-bg {
  margin-right: -($grid-gutter-width/2);
  margin-left: -($grid-gutter-width/2);
}

// // stylelint-disable-next-line scss/dollar-variable-default
// $patterns: "black-lozenge", "blackorchid", "contemporary-china", "dark-stripes", "dimention", "escheresque-ste", "footer-lodyas", "funky-lines", "ignasi-pattern-s", "moulin", "pw-maze-black", "retina-wood", "sayagata-400px", "shatered", "simple-dashed", "sports", "upfeathers";

// @each $pattern in $patterns {
//   .pattern-#{$pattern} { background-image: url("../img/patterns/#{$pattern}.png"); }
// }
