// --------------------------------------------------
// @Date:   2018-10-12 00:21:12
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:45
// --------------------------------------------------
// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    // color: $headings-color;
  }
  .lead {
    font-size: $lead-font-size;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  $next: breakpoint-next($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint) {
    .h1#{$infix},
    .h2#{$infix},
    .h3#{$infix},
    .h4#{$infix},
    .h5#{$infix},
    .h6#{$infix} {
      font-weight: $headings-font-weight;
      line-height: $headings-line-height;
    }

    .h1#{$infix} { font-size: $h1-font-size; }
    .h2#{$infix} { font-size: $h2-font-size; }
    .h3#{$infix} { font-size: $h3-font-size; }
    .h4#{$infix} { font-size: $h4-font-size; }
    .h5#{$infix} { font-size: $h5-font-size; }
    .h6#{$infix} { font-size: $h6-font-size; }

    .display#{$infix}-1,
    .display#{$infix}-2,
    .display#{$infix}-3,
    .display#{$infix}-4 {
      line-height: $display-line-height;
    }
    .display#{$infix}-1 {
      font-size: $display1-size;
      font-weight: $display1-weight;
    }
    .display#{$infix}-2 {
      font-size: $display2-size;
      font-weight: $display2-weight;
    }
    .display#{$infix}-3 {
      font-size: $display3-size;
      font-weight: $display3-weight;
    }
    .display#{$infix}-4 {
      font-size: $display4-size;
      font-weight: $display4-weight;
    }
  }
}

blockquote {
  position: relative;
  z-index: 1;
  padding-top: 8px;
  padding-left: 8px;
  font-family: $headings-font-family;
  font-size: ($font-size-base * 1.2);
  font-style: italic;
  color: $gray-900;
  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    font-family: "Font Awesome 5 Free", serif;
    font-style: normal;
    font-weight: 900;
    color: rgba($gray-800, .1);
  }
  &::before {
    top: 0;
    left: 0;
    content: $unicode_quote_left;
  }
  &::after {
    right: 0;
    bottom: 0;
    content: $unicode_quote_right;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .post-dark & {
    &::before {
      color: rgba($white, .1);
    }
  }
}
