// --------------------------------------------------
// @Date:   2019-04-22 09:23:13
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:58
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  @each $color, $value in $c-theme-colors {
    .alert-#{$color} {
      @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
    }
  }
}
