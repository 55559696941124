// --------------------------------------------------
// @Date:   2019-03-11 14:05:15
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-03-11 15:39:31
// --------------------------------------------------

.widget-categories {
  ul {
    padding: 0;
    margin-bottom: .5rem;
    list-style-type: none;
  }
  li {
    padding-left: 1.2rem;
    &::before {
      position: absolute;
      display: block;
      margin-left: -.8rem;
      font-family: "Font Awesome 5 Free", serif;
      font-style: normal;
      font-weight: 900;
      content: $unicode_angle_right;
      opacity: .6;
    }
    li {
      margin-top: .3rem;
    }
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
    > li {
      width: 50%;
      padding-right: 1.2rem;
      margin-bottom: 1rem;
    }
  }
  @include media-breakpoint-up(sm) {
    > ul > li {
      width: 33.33%;
    }
  }
  @include media-breakpoint-up(md) {
    > ul > li {
      width: 25%;
    }
  }
  @include media-breakpoint-up(lg) {
    > ul > li {
      width: 20%;
    }
  }
  @include media-breakpoint-up(xl) {
    > ul > li {
      width: 100%;
      padding-right: 0;
      margin-bottom: 0;
    }
  }
}
