// --------------------------------------------------
// @Date:   2019-04-26 08:20:24
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:51
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .nav-tabs {
    .nav-link.active,
    .nav-item.show .nav-link {
      color: $nav-tabs-link-active-color;
    }
  }

  .nav-pills {
    .nav-link.active,
    .show > .nav-link {
      background-color: $nav-pills-link-active-bg;
    }
  }
}
