// --------------------------------------------------
// @Date:   2019-01-28 14:48:04
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-05-02 00:43:52
// --------------------------------------------------

body {
  @if variable-exists(is_override_bootstrap) {
    font-family: $font-family-base;
    font-size: $font-size-base;
  }
  overflow-x: hidden;
}
