// --------------------------------------------------
// @Date:   2019-02-13 08:35:48
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-02-13 09:12:40
// --------------------------------------------------

.banner {
  position: relative;
  background-color: $gray-300;
}
.banner-468x60 {
  width: 468px;
  height: 60px;
}
.banner-728x90 {
  width: 728px;
  height: 90px;
}
.banner-300x250 {
  width: 300px;
  height: 250px;
}
.banner-120x600 {
  width: 120px;
  height: 600px;
}
.banner-160x600 {
  width: 160px;
  height: 600px;
}
.banner-300x600 {
  width: 300px;
  height: 600px;
}
.banner-234x60 {
  width: 234px;
  height: 600px;
}
.banner-88x31 {
  width: 88px;
  height: 31px;
}
.banner-120x60 {
  width: 120px;
  height: 60px;
}
.banner-120x90 {
  width: 120px;
  height: 90px;
}
.banner-120x240 {
  width: 120px;
  height: 240px;
}
