// --------------------------------------------------
// @Date:   2018-09-30 22:34:14
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-27 16:46:16
// --------------------------------------------------

$white:    #fff !default; // required by this file
$gray-400: #ced4da !default; // required by this file
$gray-800: #343a40 !default; // required by this file
$black:    #000 !default; // required by this file

$primary:   #4777fa !default; // new value
$secondary: #8a92a5 !default; // new value
$dark:      #1b1f28 !default; // new value

$primary-hover: lighten($primary, 5%) !default; // new variable

$theme-colors: () !default; // new value
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "dark":       $dark
  ),
  $theme-colors
);

$c-theme-colors: $theme-colors !default; // for non budled

// Options

// $enable-rounded:                    false !default; // new value

// Body

$body-bg:                   $white !default; // required by this file

// Links
//
// Style anchor elements.

$link-color:                        $dark !default; // new value
$link-hover-color:                  $primary !default; // new value
$link-hover-decoration:             none !default; // new value

// Grid columns

$grid-gutter-width:                 30px !default; // required by this file

// Components

$component-active-color:            $white !default;  // new value
$component-active-bg:               theme-color("primary") !default; // new value

// Typography

$font-family-sans-serif:            "Source Sans Pro", sans-serif !default; // new value
$font-family-base:                  $font-family-sans-serif !default; // new value

$font-size-base:                    1rem !default; // required by this file
$lead-font-size:                    ($font-size-base * 1.35) !default; // new value

$headings-font-family:              "Ubuntu", sans-serif !default; // new value
$headings-font-weight:              400 !default; // new value
$headings-line-height:              1.3 !default; // new value

// Buttons + Forms

$input-btn-focus-width:             .2rem !default; // required by this file
$input-btn-focus-color:             rgba($component-active-bg, .25) !default; // new value
$input-btn-focus-box-shadow:        0 0 0 $input-btn-focus-width $input-btn-focus-color !default; // new value

// Buttons

$btn-focus-box-shadow:              $input-btn-focus-box-shadow !default; // new value

// Forms

$input-focus-border-color:                      lighten($component-active-bg, 25%) !default; // new value
$input-focus-box-shadow:                        $input-btn-focus-box-shadow !default; // new value
$input-focus-width:                             $input-btn-focus-width !default; // required by this file

$custom-control-indicator-checked-bg:           $component-active-bg !default; // new value
$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5) !default; // new value
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%) !default;// new value
$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow !default; // new value

$custom-checkbox-indicator-indeterminate-bg:    $component-active-bg !default; // new value

$custom-select-box-shadow:                      inset 0 1px 2px rgba($black, .075) !default;
$custom-select-focus-width:                     $input-focus-width !default; // required by this file
$custom-select-focus-box-shadow:                0 0 0 $custom-select-focus-width $input-btn-focus-color !default; // new value

$custom-range-thumb-bg:                         $component-active-bg !default; // new value
$custom-range-thumb-active-bg:                  lighten($component-active-bg, 35%) !default; // new value
$custom-range-thumb-focus-box-shadow:           0 0 0 1px $body-bg, $input-focus-box-shadow !default;

// Dropdown

$dropdown-spacer:                   -2px !default; // new value

// Navs

$nav-tabs-link-active-color:        theme-color("primary") !default;
$nav-pills-link-active-bg:          $component-active-bg !default; // new value

// Navbar

$navbar-dark-color:                 rgba($white, .6) !default; // new value
$navbar-dark-hover-color:           theme-color("primary") !default; // new value
$navbar-dark-active-color:          theme-color("primary") !default; // new value

$navbar-light-color:                rgba($dark, .6) !default; // new value
$navbar-light-hover-color:          theme-color("primary") !default; // new value
$navbar-light-active-color:         theme-color("primary") !default; // new value

// Pagination

$pagination-color:                  $link-color !default; // new value
$pagination-bg:                     transparent !default; // new value
$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default; // new value

$pagination-hover-color:            theme-color("primary") !default;
$pagination-hover-bg:               transparent !default;
$pagination-hover-border-color:     theme-color("primary") !default;

$pagination-active-bg:              $component-active-bg !default; // new value
$pagination-active-border-color:    $pagination-active-bg !default; // new value

// Dropdown
$dropdown-link-color:               rgba($dark, .6) !default; // new value
$dropdown-link-hover-color:         theme-color("primary") !default !default; // new value

// $dropdown-link-active-color:        $component-active-color !default;  // new value
$dropdown-link-active-bg:           $component-active-bg !default; // new value


// Carousel

$carousel-control-width:            32px !default; // new value
$carousel-control-opacity:          .5 !default; // new value

$carousel-indicator-active-only-bg:      theme-color("primary") !default;

// Progress bars
$progress-bar-bg:                   theme-color("primary") !default; // new value

// List Group

$list-group-active-bg:              $component-active-bg !default; // new value

// Opacity

$opacitys: (
  0: 0,
  1: .1,
  2: .2,
  3: .3,
  4: .4,
  5: .5,
  6: .6,
  7: .7,
  8: .8,
  9: .9,
  10: 1
) !default;

// Heights

$height-1: 210px !default;
$height-2: $height-1 * 2 !default;
$height-3: $height-1 * 3 !default;

// Header

$header-overlaped-height: $height-1 !default;

// $header-nav-link-height: 42px !default;

$sidebar-w: 300px !default;
$sidebar-sm-w: 160px !default;
$readerbar-w: 680px !default;

// Section

// $section-header-line-height: 48px !default;
$section-header-pt: 1.1rem !default;
$section-header-pb: 1rem !default;
// $section-header-total-h: ( $section-header-line-height + $section-header-mt + $section-header-mb) !default;

$section-separator-width: 32px !default;
$section-separator-height: 4px !default;

$section-title-color: $dark !default;
$section-title-hover-color: $primary-hover !default;
$section-subnav-color: $secondary !default;
$section-subnav-hover-color: $primary !default;
$section-heading-color: $dark !default;
$section-heading-hover-color: $primary !default;

$section-dark-color: $white !default;
$section-dark-title-color: $white !default;
$section-dark-title-hover-color: $white !default;
$section-dark-subnav-color: $gray-400 !default;
$section-dark-subnav-hover-color: $white !default;
$section-dark-heading-color: $white !default;
$section-dark-heading-hover-color: $primary !default;

$section-dark-link-color: darken($white, 3%) !default;
$section-dark-link-hover-color: $primary !default;
$section-dark-heading-color: $white !default;
$section-dark-heading-hover-color: $primary !default;

// Post

$post-title-color: $dark !default;
$post-title-hover-color: $primary !default;
$post-title-border-hover-color: $primary !default;
$post-meta-color: $secondary !default;
$post-meta-hover-color: $primary !default;
$post-excerpt-color: $secondary !default;
$post-blockquote-color: $dark !default;

$post-dark-color: darken($white, 3%) !default;
$post-dark-hover-color: $primary !default;
$post-dark-title-color: darken($white, 5%) !default;
$post-dark-title-hover-color: $primary !default;
$post-dark-title-border-hover-color: $white !default;
$post-dark-meta-color: $gray-400 !default;
$post-dark-meta-hover-color: $primary !default;
$post-dark-excerpt-color: $gray-400 !default;
$post-dark-blockquote-color: $white !default;

$post-meta-font-size: ($font-size-base * .97) !default;
$post-summary-meta-font-size: ($font-size-base * .8) !default;
$post-excerpt-font-size: ($font-size-base * .95) !default;

$post-padding-top: ($grid-gutter-width/2) !default;
$post-padding-bottom: ($grid-gutter-width/2) !default;

$divider-light: rgba($gray-800, .1) !default;
$divider-dark: rgba($white, .1) !default;

// Summary
// $summary-padding-top: ($grid-gutter-width/2) !default;
// $summary-padding-bottom: ($grid-gutter-width/2) !default;

// Font awesome Unicode used for components
$unicode_close: "\f067" !default;
$unicode_quote_left: "\f10d" !default;
$unicode_quote_right: "\f10e" !default;
$unicode_angle_left: "\f104" !default;
$unicode_angle_right: "\f105" !default;
