// --------------------------------------------------
// @Date:   2019-03-30 09:37:58
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:52
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .form-control {
    @include c-form-control-focus();
  }
}

.form-label {
  cursor: pointer;
}

.form-control-transparent {
  padding-right: 0;
  padding-left: 0;
  &,
  &:focus {
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
  }
  .navbar-light & {
    color: $navbar-light-color;
    border-color: rgba($gray-800, .1);
    &:focus {
      color: $navbar-light-active-color;
      border-color: $navbar-light-active-color;
      & + .nav-link {
        color: $navbar-light-active-color;
      }
    }
  }
  .navbar-dark & {
    color: $navbar-dark-color;
    border-color: rgba($white, .1);
    &:focus {
      color: $navbar-dark-active-color;
      border-color: $navbar-dark-active-color;
      & + .nav-link {
        color: $navbar-dark-active-color;
      }
    }
  }
}

.form-control-search {
  width: 0;
  transition: all .2s ease-out;
  &:focus {
    width: 100%;
  }
}
