// --------------------------------------------------
// @Date:   2019-04-22 09:27:03
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:57
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  @each $color, $value in $c-theme-colors {
    .badge-#{$color} {
      @include badge-variant($value);
    }
  }
}
