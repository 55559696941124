// --------------------------------------------------
// @Date:   2018-10-07 22:34:35
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-03-06 22:35:50
// --------------------------------------------------

.overlay {
  position: absolute !important; /* stylelint-disable-line declaration-no-important */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-t,
.overlay-t-l,
.overlay-t-r {
  top: 0;
  bottom: auto;
}

.overlay-b,
.overlay-b-l,
.overlay-b-r {
  top: auto;
  bottom: 0;
}

.overlay-l,
.overlay-t-l,
.overlay-b-l {
  right: auto;
  left: 0;
}

.overlay-r,
.overlay-t-r,
.overlay-b-r {
  right: 0;
  left: auto;
}

// .overlay-c-c {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.overlay[class^="bg-gradient-b-"],
.overlay[class*=" bg-gradient-b-"] {
  top: auto;
  bottom: 0;
  height: 30%;
}
