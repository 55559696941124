// --------------------------------------------------
// @Date:   2018-10-26 09:35:05
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:43
// --------------------------------------------------

// stylelint-disable declaration-no-important
// stylelint-disable selector-no-qualifying-type

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  @each $color, $value in $c-theme-colors {
    .border-#{$color} {
      border-color: $value !important;
    }
  }
}

@each $color, $value in $grays {
  @include text-emphasis-variant(".text-gray-#{$color}", $value);
}

a.text-hover-white {
  @include hover-focus {
    color: $white !important;
  }
}

a.text-hover-primary {
  @include hover-focus {
    color: $primary !important;
  }
}

a {
  @include hover-focus {
    .text-hover-white {
      color: $white !important;
    }
  }
  @include hover-focus {
    .text-hover-primary {
      color: $primary !important;
    }
  }
}
