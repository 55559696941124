// --------------------------------------------------
// @Date:   2019-04-24 20:33:22
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:51
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .list-group-item {
    &.active {
      background-color: $list-group-active-bg;
    }
  }

  @each $color, $value in $c-theme-colors {
    @include list-group-item-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
  }
}
