// --------------------------------------------------
// @Date:   2019-04-08 04:52:41
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:56
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .btn {
    &:focus,
    &.focus {
      box-shadow: $btn-focus-box-shadow;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }

  //
  // Alternate buttons
  //
  @each $color, $value in $c-theme-colors {
    .btn-#{$color} {
      @include button-variant($value, $value);
    }
  }

  @each $color, $value in $c-theme-colors {
    .btn-outline-#{$color} {
      @include button-outline-variant($value);
    }
  }

  .btn-link {
    color: $link-color;

    @include hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }
}

.btn-hover-primary:hover,
.btn-hover-primary:focus,
.btn-hover-primary.focus,
.btn-hover-primary:not(:disabled):not(.disabled):active,
.btn-hover-primary:not(:disabled):not(.disabled).active,
.btn-hover-outline-primary:focus,
.btn-hover-outline-primary.focus,
.btn-hover-outline-primary:not(:disabled):not(.disabled):active,
.btn-hover-outline-primary:not(:disabled):not(.disabled).active {
  color: $white;
  background-color: $primary;
  border-color: $primary;
  box-shadow: none;
}

.btn-hover-outline-primary {
  &:hover {
    color: $primary;
    background-color: transparent;
    border-color: $primary;
  }
}
