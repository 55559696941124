// --------------------------------------------------
// @Date:   2018-10-04 22:53:33
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:45
// --------------------------------------------------

// stylelint-disable declaration-no-important

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  @each $color, $value in $c-theme-colors {
    @include bg-variant(".bg-#{$color}", $value);
  }

  @if $enable-gradients {
    @each $color, $value in $c-theme-colors {
      @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
    }
  }
}

@each $color, $value in $grays {
  @include bg-variant(".bg-gray-#{$color}", $value);
}

@each $color, $value in $grays {
  .bg-gradient-b-#{$color} {
    @include gradient-y(rgba($value, 0), $value, 0%, 100%);
  }
}

.bg-black {
  background-color: $black !important;
}
