// --------------------------------------------------
// @Date:   2019-04-26 08:38:18
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:48
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .progress-bar {
    background-color: $progress-bar-bg;
  }
}
