// --------------------------------------------------
// @Date:   2018-09-27 19:46:42
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-05-19 09:32:51
// --------------------------------------------------

// stylelint-disable declaration-no-important
// stylelint-disable selector-max-compound-selectors

.posts {
  margin-top: -($post-padding-top);
  // margin-bottom: $post-padding-bottom;

  &.row > .section,
  &.row > *:not(.post) > .section {
    margin-top: $post-padding-top;
    // > .posts {
    //   margin-top: -($post-margin-bottom);
    // }
  }
  .post {
    margin-top: 0;
  }
}
.post {
  position: relative;
  display: flex;
  flex-direction: column;
  // margin-top: -($post-padding-top);
}
.post-overlay-link {
  .post:hover & {
    z-index: 1;
  }
  .post-summary & {
    top: $post-padding-top;
    bottom: $post-padding-bottom;
  }
  .row & {
    right: ($grid-gutter-width/2);
    left: ($grid-gutter-width/2);
  }
}
.post-summary {
  padding-top: $post-padding-bottom;
  padding-bottom: $post-padding-bottom;
}

.post-bg-overlay {
  top: ($grid-gutter-width/2);
  right: ($grid-gutter-width/2);
  bottom: ($grid-gutter-width/2);
  left: ($grid-gutter-width/2);
  display: flex;
  flex-direction: column;
  padding-top: $post-padding-bottom;
  padding-bottom: $post-padding-bottom;

  .row > .post > & {
    padding-right: ($grid-gutter-width/2);
    padding-left: ($grid-gutter-width/2);
  }
}

@if $enable-grid-classes {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .post.gutter#{$infix} {
        padding-top: $post-padding-bottom;
        padding-bottom: $post-padding-bottom;

        > .post-bg-overlay {
          padding-top: $post-padding-bottom;
          padding-bottom: $post-padding-bottom;
        }

        .row > &,
        .row > & > .post-bg-overlay {
          padding-right: ($grid-gutter-width/2);
          padding-left: ($grid-gutter-width/2);
        }
      }

      .post.guttert#{$infix} {
        padding-top: $post-padding-bottom;

        > .post-bg-overlay {
          padding-top: $post-padding-bottom;
        }
      }

      .post.gutterb#{$infix} {
        padding-bottom: $post-padding-bottom;

        > .post-bg-overlay {
          padding-bottom: $post-padding-bottom;
        }
      }

      .post.gutterx#{$infix},
      .post.gutterr#{$infix} {
        .row > &,
        .row > & > .post-bg-overlay {
          padding-right: ($grid-gutter-width/2);
        }
      }

      .post.gutterx#{$infix},
      .post.gutterl#{$infix} {
        .row > &,
        .row > & > .post-bg-overlay {
          padding-left: ($grid-gutter-width/2);
        }
      }

      .post.no-gutter#{$infix} {
        padding-top: 0;
        padding-bottom: 0;

        > .post-bg-overlay {
          padding-top: 0;
          padding-bottom: 0;
        }

        .row > &,
        .row > & > .post-bg-overlay {
          padding-right: 0;
          padding-left: 0;
        }
      }

      .post.no-guttert#{$infix} {
        padding-top: 0;

        > .post-bg-overlay {
          padding-top: 0;
        }
      }

      .post.no-gutterb#{$infix} {
        padding-bottom: 0;

        > .post-bg-overlay {
          padding-bottom: 0;
        }
      }

      .post.no-gutterx#{$infix},
      .post.no-gutterr#{$infix} {
        .row > &,
        .row > & > .post-bg-overlay {
          padding-right: 0;
        }
      }

      .post.no-gutterx#{$infix},
      .post.no-gutterl#{$infix} {
        .row > &,
        .row > & > .post-bg-overlay {
          padding-left: 0;
        }
      }
    }
  }
}

.post-thumbnail {
  position: relative;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.post-title {
  position: relative;

  a:hover,
  .post-overlay-link:hover ~ & a,
  .post-overlay-link:hover ~ div & a {
    text-decoration: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  > a:focus,
  .post-overlay-link:focus ~ & > a,
  .post-overlay-link:focus ~ div & > a {
    color: $white !important;
    background-color: $post-title-hover-color !important;
  }

  &:last-child {
    margin-bottom: 0;
  }
  > span {
    display: inline-block;
  }

}

.post-meta {
  font-size: $post-meta-font-size;
  // position: relative;
  // display: inline-block;
  // white-space: nowrap;
  .post-summary & {
    font-size: $post-summary-meta-font-size;
  }

  p {
    margin-bottom: ($paragraph-margin-bottom * .7);
  }

  a {
    position: relative;
    z-index: 5;
  }
}

.post-excerpt {
  position: relative;

  p:not(.lead) {
    margin-bottom: .75em;
  }
}

.post-readerbar {
  @include media-breakpoint-up(md) {
    > p,
    > figure {
      display: block;
      max-width: $readerbar-w;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.post-readerbarr {
  @include media-breakpoint-up(md) {
    > p,
    > figure {
      display: block;
      max-width: $readerbar-w;
      margin-left: auto;
    }
  }
}
.post-readerbarl {
  @include media-breakpoint-up(md) {
    > p,
    > figure {
      display: block;
      max-width: $readerbar-w;
      margin-right: auto;
    }
  }
}

.post-content {
  // font-size: 105%;
  // line-height: 1.6;
  position: relative;
  .figure {
    margin-top: .5rem;
  }
  .section {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  a {
    @include hover {
      text-decoration: underline;
    }
  }
  @include media-breakpoint-down(md) {
    > .alignright,
    > .alignleft,
    > .float-right,
    > .float-left {
      float: none !important;
    }
  }
  @include media-breakpoint-up(md) {
    > .w-window {
      max-width: initial !important;
    }
    > .alignright {
      float: right !important;
    }
    > .alignleft {
      float: left !important;
    }
    > .alignright,
    > .float-right {
      float: right !important;
      margin-left: $spacer;
    }
    > .alignleft,
    > .float-left {
      float: left !important;
      margin-right: $spacer;
    }

    $container-width: map-get($container-max-widths, "xl");
    $outer-width: $container-width - $readerbar-w;

    $small-width: $outer-width * 55 / 100;
    blockquote {
      max-width: calc(#{$small-width} - #{$grid-gutter-width}) !important;
    }

    .size-sm {
      max-width: calc(45% - #{$grid-gutter-width/2}) !important;
    }
    .size-md {
      max-width: calc(60% - #{$grid-gutter-width/2}) !important;
    }
    .size-lg {
      max-width: $readerbar-w !important;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .size-xl {
      max-width: 100% !important;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  @include media-breakpoint-up(lg) {
    .size-sm {
      max-width: calc(33% - #{($grid-gutter-width/2) + 1px}) !important;
    }
    .size-md {
      max-width: calc(50% - #{($grid-gutter-width/2) + 1px}) !important;
    }
  }
  @include media-breakpoint-up(xl) {
    $container-width: map-get($container-max-widths, "xl");
    $outer-width: $container-width - $readerbar-w;

    $small-width: $outer-width * 60 / 100;
    // .size-sm {
    //   max-width: calc(#{$small-width} - #{$grid-gutter-width}) !important;
    // }
    // $medium-width: $outer-width * 80 / 100;
    // .size-md {
    //   max-width: calc(#{$medium-width} - #{$grid-gutter-width}) !important;
    // }
  }
}

.post-light {
  .post-title {
    color: $post-title-color;
    a {
      color: $post-title-color;
      border-color: rgba($post-title-border-hover-color, .2);
    }
  }

  .post-title a:hover,
  .post-overlay-link:hover ~ .post-title a,
  .post-overlay-link:hover ~ div .post-title a {
    color: $post-title-hover-color !important;
  }

  .post-meta {
    color: $post-meta-color;
    a {
      color: $post-meta-color;
      @include hover {
        color: $post-meta-hover-color;
      }
    }
  }

  .post-excerpt {
    position: relative;
    color: $post-excerpt-color;
  }

  .post-content,
  &.post-content {
    .blockquote {
      color: $post-blockquote-color;
    }
  }

}

.post-dark {
  color: $post-dark-color;
  a {
    color: $post-dark-color;
    &:hover {
      color: $post-dark-hover-color;
    }
  }
  .post-title {
    color: $post-dark-title-color;
    a {
      color: $post-dark-title-color;
      border-color: rgba($post-dark-title-border-hover-color, .2);
      &:hover {
        color: $post-dark-title-hover-color;
      }
    }
  }

  .post-title a:hover,
  .post-overlay-link:hover ~ .post-title a,
  .post-overlay-link:hover ~ div .post-title a {
    color: $post-dark-title-hover-color;
  }

  .post-meta {
    color: $post-dark-meta-color;
    a {
      color: $post-dark-meta-color;
      @include hover {
        color: $post-dark-meta-hover-color;
      }
    }
  }

  .post-excerpt {
    color: $post-dark-excerpt-color;
  }

  .post-content,
  &.post-content {
    .blockquote {
      color: $post-dark-blockquote-color;
    }
  }
}
