// --------------------------------------------------
// @Date:   2019-04-26 05:49:47
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-27 09:34:51
// --------------------------------------------------

@mixin c-form-control-focus() {
  &:focus {
    border-color: $input-focus-border-color;
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}

