// --------------------------------------------------
// @Date:   2019-02-19 05:31:24
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-27 09:23:18
// --------------------------------------------------

.figure {
  img {
    margin-bottom: 0;
  }
}

.figure-caption {
  padding-top: ($spacer * .5);
  padding-bottom: ($spacer * .5);
  border-bottom: 1px solid $divider-light;
}

// stylelint-disable
.post-light .figure-caption {
  border-color: $divider-light !important;  
}

.post-dark .figure-caption {
  border-color: $divider-dark !important;  
}
