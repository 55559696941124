// --------------------------------------------------
// @Date:   2018-09-26 08:28:41
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-05-15 22:33:03
// --------------------------------------------------

/* stylelint-disable scss/dollar-variable-default */
$is_override_bootstrap: true;
/* stylelint-enable */

@import "../node_modules/bootstrap/scss/_functions";
@import "variables";
@import "../node_modules/bootstrap/scss/_variables";
@import "../node_modules/bootstrap/scss/_mixins";
@import "mixins";
@import "root";
@import "_reboot";
@import "type";
@import "images";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "tooltip";
@import "popover";
@import "carousel";

// Publcize Component
@import "background";
@import "overlay";
@import "header";
@import "section";
@import "post";
@import "rank";
@import "icon";
@import "divider";
@import "banner";

// Utilities
@import "utilities/background";
@import "utilities/borders";
@import "utilities/overflow";
@import "utilities/position";
@import "utilities/sizing";
@import "utilities/spacing";
@import "utilities/text";
@import "utilities/opacity";

@import "wp-widget";
