// --------------------------------------------------
// @Date:   2019-03-11 15:56:08
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-05-22 10:59:35
// --------------------------------------------------

// stylelint-disable declaration-no-important

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  $next: breakpoint-next($breakpoint, $grid-breakpoints);
  @include media-breakpoint-up($breakpoint) {

    @each $position in $positions {
      .position#{$infix}-#{$position} { position: $position !important; }
    }

    .t-gutter,
    .y-gutter {
      top: ($grid-gutter-width/2) !important;
    }

    .r-gutter,
    .x-gutter {
      right: ($grid-gutter-width/2) !important;
    }

    .b-gutter,
    .y-gutter {
      bottom: ($grid-gutter-width/2) !important;
    }

    .l-gutter,
    .X-gutter {
      left: ($grid-gutter-width/2) !important;
    }

  }
}

.sticky-col {
  z-index: 4;
}
