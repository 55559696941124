// --------------------------------------------------
// @Date:   2018-09-30 22:39:06
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-05-21 10:08:49
// --------------------------------------------------

// stylelint-disable declaration-no-important

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  $next: breakpoint-next($breakpoint, $grid-breakpoints);
  @include media-breakpoint-up($breakpoint) {

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        .mi#{$abbrev}#{$infix}-#{$size} { min-#{$prop}: $length !important; }
        .m#{$abbrev}#{$infix}-#{$size} { max-#{$prop}: $length !important; }

        // .#{$abbrev}#{$infix}-#{$size}vh { #{$prop}: str-replace(inspect($length), "%", "vh") !important; }
        // .mi#{$abbrev}#{$infix}-#{$size}vh { min-#{$prop}: str-replace(inspect($length), "%", "vh") !important; }
        // .m#{$abbrev}#{$infix}-#{$size}vh { max-#{$prop}: str-replace(inspect($length), "%", "vh") !important; }
      }
    }

    .h#{$infix}-100vh {
      height: 100vh !important;

      &.bg-overlapped,
      &.bg-overlappedb {
        height: calc(100vh - #{$height-1}) !important;
      }

      &.bg-overlapped-sm,
      &.bg-overlappedb-sm {
        height: calc(100vh - #{$height-1/4}) !important;
      }
    }

    .h#{$infix}-50vh {
      height: 50vh !important;

      &.bg-overlapped,
      &.bg-overlappedb {
        height: calc(50vh - #{$height-1}) !important;
      }

      &.bg-overlapped-sm,
      &.bg-overlappedb-sm {
        height: calc(50vh - #{$height-1/4}) !important;
      }
    }

    .h#{$infix}-x1 {
      height: $height-1 !important;
    }
    .h#{$infix}-x2 {
      height: $height-2 !important;
    }
    .h#{$infix}-x3 {
      height: $height-3 !important;
    }

    .mih#{$infix}-x1 {
      min-height: $height-1 !important;
    }
    .mih#{$infix}-x2 {
      min-height: $height-2 !important;
    }
    .mih#{$infix}-x3 {
      min-height: $height-3 !important;
    }

    .mih#{$infix}-100vh {
      min-height: 100vh;
    }

    .h#{$infix}-auto {
      height: auto !important;
    }

  }
}

.w-window {
  position: relative;
  right: 50% !important;
  left: 50% !important;
  width: 100vw !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {

    $col: col#{$infix};

    [class^="#{$col}"] .w-window,
    [class*=" #{$col}"] .w-window {
      width: 120vw !important;
    }
    .col#{$infix}-#{$grid-columns} .w-window {
      width: 100vw !important;
    }
  }
}

.w-readerbar {
  width: 100%;
  max-width: $readerbar-w;
}
