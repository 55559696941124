// --------------------------------------------------
// @Date:   2018-09-28 22:11:37
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-29 09:24:55
// --------------------------------------------------

// stylelint-disable declaration-no-important

@if $enable-grid-classes {
  .container {
    &.wider {
      @include media-breakpoint-up(xl, $grid-breakpoints) {
        max-width: 1280px;
      }
    }
  }

  %new-col {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: ($grid-gutter-width/2);
    padding-left: ($grid-gutter-width/2);
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .col#{$infix}-sbar,
    .col#{$infix}-sbar-sm,
    .col#{$infix}-readerbar {
      @extend %new-col;
    }
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .row#{$infix}-block {
        @include c-make-row-block();
        @include c-make-grid-block-columns();
      }

      $sidebar-w-gutter: $sidebar-w + $grid-gutter-width !default;
      .col#{$infix}-sbar {
        flex: 0 0 $sidebar-w-gutter;
        max-width: $sidebar-w-gutter;
      }

      $sidebar-sm-w-gutter: $sidebar-sm-w + $grid-gutter-width !default;
      .col#{$infix}-sbar-sm {
        flex: 0 0 $sidebar-sm-w-gutter;
        max-width: $sidebar-sm-w-gutter;
      }

      $readerbar-w-gutter: $readerbar-w + $grid-gutter-width !default;
      .col#{$infix}-readerbar {
        flex: 0 0 ( $readerbar-w-gutter );
        max-width: ( $readerbar-w-gutter );
      }

    }
  }
}
