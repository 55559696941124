// --------------------------------------------------
// @Date:   2019-02-28 11:34:01
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-03-11 14:18:12
// --------------------------------------------------

.collapsed-info {
  // @include transition($transition-collapse);
  .collapse.show ~ & {
    display: none;
  }
}
[data-toggle="collapse"] {
  .fas {
    .collapse:not(.show) ~ &::before {
      content: $unicode_close;
    }
  }
}
