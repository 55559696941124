// --------------------------------------------------
// @Date:   2018-09-28 22:11:05
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:31:15
// --------------------------------------------------

@mixin c-make-grid-block-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      > .col#{$infix}-#{$i} {
        // @extend %grid-column;
      }
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns

      @for $i from 1 through $columns {
        > .col#{$infix}-#{$i} {
          @include c-make-col-block($i, $columns);
        }
      }

    }
  }
}
