// --------------------------------------------------
// @Date:   2019-04-26 05:53:40
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:53
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      @include gradient-bg($custom-control-indicator-checked-bg);
    }

    &:focus ~ .custom-control-label::before {
      box-shadow: $custom-control-indicator-focus-box-shadow;
    }

    &:active ~ .custom-control-label::before {
      background-color: $custom-control-indicator-active-bg;
    }
  }

  .custom-checkbox {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        @include gradient-bg($custom-control-indicator-checked-bg);
      }
    }

    .custom-control-input:indeterminate ~ .custom-control-label {
      &::before {
        @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
      }
    }

    .custom-control-input:disabled {
      &:checked ~ .custom-control-label::before {
        background-color: $custom-control-indicator-checked-disabled-bg;
      }
      &:indeterminate ~ .custom-control-label::before {
        background-color: $custom-control-indicator-checked-disabled-bg;
      }
    }
  }

  .custom-radio {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        @include gradient-bg($custom-control-indicator-checked-bg);
      }
    }

    .custom-control-input:disabled {
      &:checked ~ .custom-control-label::before {
        background-color: $custom-control-indicator-checked-disabled-bg;
      }
    }
  }

  .custom-select {
    &:focus {
      @if $enable-shadows {
        box-shadow: $custom-select-box-shadow, $custom-select-focus-box-shadow;
      } @else {
        box-shadow: $custom-select-focus-box-shadow;
      }
    }
  }

  .custom-range {

    &:focus {
      &::-webkit-slider-thumb { box-shadow: $custom-range-thumb-focus-box-shadow; }
      &::-moz-range-thumb     { box-shadow: $custom-range-thumb-focus-box-shadow; }
      &::-ms-thumb            { box-shadow: $custom-range-thumb-focus-box-shadow; }
    }

    &::-webkit-slider-thumb {
      @include gradient-bg($custom-range-thumb-bg);

      &:active {
        @include gradient-bg($custom-range-thumb-active-bg);
      }
    }

    &::-moz-range-thumb {

      &:active {
        @include gradient-bg($custom-range-thumb-active-bg);
      }
    }

    &::-ms-thumb {
      margin-right: $custom-range-thumb-focus-box-shadow-width;
      margin-left: $custom-range-thumb-focus-box-shadow-width;

      &:active {
        @include gradient-bg($custom-range-thumb-active-bg);
      }
    }

  }
}
