// --------------------------------------------------
// @Date:   2019-02-18 20:43:32
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-03-11 10:52:40
// --------------------------------------------------

// stylelint-disable

hr.divider,
hr.divider-top,
hr.divider-bottom {
  position: relative;
}
.divider-top {
  border-top: 1px solid;
}
.divider-bottom {
  border-bottom: 1px solid;
}
.post-light .divider,
.post-light.divider,
.navbar-light .divider,
.navbar-light.divider,
.divider.divider-light {
  border-color: rgba($gray-800, .1) !important;
}
.post-dark .divider,
.post-dark.divider,
.navbar-dark .divider,
.navbar-dark.divider,
.divider.divider-dark {
  border-color: rgba($white, .1) !important;
}
