// --------------------------------------------------
// @Date:   2019-04-26 05:36:10
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:46
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .tooltip {
    @include c-reset-text();
  }
}
