// --------------------------------------------------
// @Date:   2018-12-10 23:23:03
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-02-17 05:12:28
// --------------------------------------------------

.icon-xs {
  font-size: 1.5rem;
}
.icon-sm {
  font-size: 2rem;
}
.icon-md {
  font-size: 2.5rem;
}
.icon-lg {
  font-size: 3rem;
}
.icon-xl {
  font-size: 4rem;
}
.icon-full {
  font-size: 5rem;
}
