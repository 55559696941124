// --------------------------------------------------
// @Date:   2018-10-18 19:58:15
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:50
// --------------------------------------------------

// stylelint-disable selector-max-class
// stylelint-disable selector-max-compound-selectors

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .navbar-light {
    .navbar-brand {
      color: $navbar-light-active-color;

      @include hover-focus {
        color: $navbar-light-active-color;
      }
    }

    .navbar-nav {
      .nav-link {
        color: $navbar-light-color;

        @include hover-focus {
          color: $navbar-light-hover-color;
        }

        &.disabled {
          color: $navbar-light-disabled-color;
        }
      }

      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: $navbar-light-active-color;
      }
    }

    .navbar-toggler {
      color: $navbar-light-color;
    }

    .navbar-text {
      color: $navbar-light-color;
      a {
        color: $navbar-light-active-color;

        @include hover-focus {
          color: $navbar-light-active-color;
        }
      }
    }
  }

  .navbar-dark {
    .navbar-brand {
      color: $navbar-dark-active-color;

      @include hover-focus {
        color: $navbar-dark-active-color;
      }
    }

    .navbar-nav {
      .nav-link {
        color: $navbar-dark-color;

        @include hover-focus {
          color: $navbar-dark-hover-color;
        }

        &.disabled {
          color: $navbar-dark-disabled-color;
        }
      }

      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: $navbar-dark-active-color;
      }
    }

    .navbar-toggler {
      color: $navbar-dark-color;
    }

    .navbar-text {
      color: $navbar-dark-color;
      a {
        color: $navbar-dark-active-color;

        @include hover-focus {
          color: $navbar-dark-active-color;
        }
      }
    }
  }
}

.navbar-collapse {
  padding-right: calc(#{$grid-gutter-width/2} - #{$navbar-nav-link-padding-x});
  padding-left: calc(#{$grid-gutter-width/2} - #{$navbar-nav-link-padding-x});
}
