// --------------------------------------------------
// @Date:   2018-10-07 22:42:19
// @Last modified by:   faiqfardian
// @Last Modified time: 2018-10-07 22:50:20
// --------------------------------------------------

// stylelint-disable declaration-no-important

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  $next: breakpoint-next($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint) {
    @each $level, $opacity in $opacitys {
      .o-#{$level} { opacity: $opacity !important; }
      .o-hover-#{$level} {
        &:hover,
        .post:hover & {
          opacity: $opacity !important;
        }
      }
    }
  }
}
