// --------------------------------------------------
// @Date:   2018-09-28 22:11:05
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-26 05:26:24
// --------------------------------------------------

// stylelint-disable declaration-no-important

@mixin c-make-row-block() {
  display: block !important;
  flex: none;
  @include clearfix();
  & > * {
    display: block;
    flex: none;
    float: left;
    width: 100%;
  }
}

@mixin c-make-col-block($size, $columns: $grid-columns) {
  width: percentage($size / $columns);
}
