// --------------------------------------------------
// @Date:   2019-04-25 15:37:50
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:47
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  :root {
    @each $color, $value in $c-theme-colors {
      --#{$color}: #{$value};
    }

    --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  }

  a {
    color: $link-color;

    @include hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }

}
