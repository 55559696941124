// --------------------------------------------------
// @Date:   2019-04-26 08:30:52
// @Last modified by:   faiqfardian
// @Last Modified time: 2019-04-28 20:25:49
// --------------------------------------------------

@if variable-exists(is_override_bootstrap) {
  // Override Bootstrap default rules

  .page-link {
    color: $pagination-color;
    background-color: $pagination-bg;

    &:hover {
      color: $pagination-hover-color;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
    }

    &:focus {
      box-shadow: $pagination-focus-box-shadow;
    }
  }
  .page-item {
    &.active .page-link {
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border-color;
    }
  }
}
