@charset "UTF-8";
/* stylelint-disable scss/dollar-variable-default */
/* stylelint-enable */
:root {
  --primary: #4777fa;
  --secondary: #8a92a5;
  --dark: #1b1f28;
  --font-family-sans-serif: "Source Sans Pro", sans-serif;
}

a {
  color: #1b1f28;
}

a:hover {
  color: #4777fa;
  text-decoration: none;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

.lead {
  font-size: 1.35rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
  line-height: 1.3;
}

.h1 {
  font-size: 2.5rem;
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.75rem;
}

.h4 {
  font-size: 1.5rem;
}

.h5 {
  font-size: 1.25rem;
}

.h6 {
  font-size: 1rem;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  line-height: 1.3;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
}

@media (min-width: 576px) {
  .h1-sm,
  .h2-sm,
  .h3-sm,
  .h4-sm,
  .h5-sm,
  .h6-sm {
    font-weight: 400;
    line-height: 1.3;
  }
  .h1-sm {
    font-size: 2.5rem;
  }
  .h2-sm {
    font-size: 2rem;
  }
  .h3-sm {
    font-size: 1.75rem;
  }
  .h4-sm {
    font-size: 1.5rem;
  }
  .h5-sm {
    font-size: 1.25rem;
  }
  .h6-sm {
    font-size: 1rem;
  }
  .display-sm-1,
  .display-sm-2,
  .display-sm-3,
  .display-sm-4 {
    line-height: 1.3;
  }
  .display-sm-1 {
    font-size: 6rem;
    font-weight: 300;
  }
  .display-sm-2 {
    font-size: 5.5rem;
    font-weight: 300;
  }
  .display-sm-3 {
    font-size: 4.5rem;
    font-weight: 300;
  }
  .display-sm-4 {
    font-size: 3.5rem;
    font-weight: 300;
  }
}

@media (min-width: 768px) {
  .h1-md,
  .h2-md,
  .h3-md,
  .h4-md,
  .h5-md,
  .h6-md {
    font-weight: 400;
    line-height: 1.3;
  }
  .h1-md {
    font-size: 2.5rem;
  }
  .h2-md {
    font-size: 2rem;
  }
  .h3-md {
    font-size: 1.75rem;
  }
  .h4-md {
    font-size: 1.5rem;
  }
  .h5-md {
    font-size: 1.25rem;
  }
  .h6-md {
    font-size: 1rem;
  }
  .display-md-1,
  .display-md-2,
  .display-md-3,
  .display-md-4 {
    line-height: 1.3;
  }
  .display-md-1 {
    font-size: 6rem;
    font-weight: 300;
  }
  .display-md-2 {
    font-size: 5.5rem;
    font-weight: 300;
  }
  .display-md-3 {
    font-size: 4.5rem;
    font-weight: 300;
  }
  .display-md-4 {
    font-size: 3.5rem;
    font-weight: 300;
  }
}

@media (min-width: 992px) {
  .h1-lg,
  .h2-lg,
  .h3-lg,
  .h4-lg,
  .h5-lg,
  .h6-lg {
    font-weight: 400;
    line-height: 1.3;
  }
  .h1-lg {
    font-size: 2.5rem;
  }
  .h2-lg {
    font-size: 2rem;
  }
  .h3-lg {
    font-size: 1.75rem;
  }
  .h4-lg {
    font-size: 1.5rem;
  }
  .h5-lg {
    font-size: 1.25rem;
  }
  .h6-lg {
    font-size: 1rem;
  }
  .display-lg-1,
  .display-lg-2,
  .display-lg-3,
  .display-lg-4 {
    line-height: 1.3;
  }
  .display-lg-1 {
    font-size: 6rem;
    font-weight: 300;
  }
  .display-lg-2 {
    font-size: 5.5rem;
    font-weight: 300;
  }
  .display-lg-3 {
    font-size: 4.5rem;
    font-weight: 300;
  }
  .display-lg-4 {
    font-size: 3.5rem;
    font-weight: 300;
  }
}

@media (min-width: 1200px) {
  .h1-xl,
  .h2-xl,
  .h3-xl,
  .h4-xl,
  .h5-xl,
  .h6-xl {
    font-weight: 400;
    line-height: 1.3;
  }
  .h1-xl {
    font-size: 2.5rem;
  }
  .h2-xl {
    font-size: 2rem;
  }
  .h3-xl {
    font-size: 1.75rem;
  }
  .h4-xl {
    font-size: 1.5rem;
  }
  .h5-xl {
    font-size: 1.25rem;
  }
  .h6-xl {
    font-size: 1rem;
  }
  .display-xl-1,
  .display-xl-2,
  .display-xl-3,
  .display-xl-4 {
    line-height: 1.3;
  }
  .display-xl-1 {
    font-size: 6rem;
    font-weight: 300;
  }
  .display-xl-2 {
    font-size: 5.5rem;
    font-weight: 300;
  }
  .display-xl-3 {
    font-size: 4.5rem;
    font-weight: 300;
  }
  .display-xl-4 {
    font-size: 3.5rem;
    font-weight: 300;
  }
}

blockquote {
  position: relative;
  z-index: 1;
  padding-top: 8px;
  padding-left: 8px;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  color: #212529;
}

blockquote::before, blockquote::after {
  position: absolute;
  z-index: -1;
  font-family: "Font Awesome 5 Free", serif;
  font-style: normal;
  font-weight: 900;
  color: rgba(52, 58, 64, 0.1);
}

blockquote::before {
  top: 0;
  left: 0;
  content: "";
}

blockquote::after {
  right: 0;
  bottom: 0;
  content: "";
}

blockquote p:last-child {
  margin-bottom: 0;
}

.post-dark blockquote::before {
  color: rgba(255, 255, 255, 0.1);
}

.figure img {
  margin-bottom: 0;
}

.figure-caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(52, 58, 64, 0.1);
}

.post-light .figure-caption {
  border-color: rgba(52, 58, 64, 0.1) !important;
}

.post-dark .figure-caption {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

@media (min-width: 1200px) {
  .container.wider {
    max-width: 1280px;
  }
}

.col-sbar,
.col-sbar-sm,
.col-readerbar, .col-sm-sbar,
.col-sm-sbar-sm,
.col-sm-readerbar, .col-md-sbar,
.col-md-sbar-sm,
.col-md-readerbar, .col-lg-sbar,
.col-lg-sbar-sm,
.col-lg-readerbar, .col-xl-sbar,
.col-xl-sbar-sm,
.col-xl-readerbar {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.row-block {
  display: block !important;
  flex: none;
}

.row-block::after {
  display: block;
  clear: both;
  content: "";
}

.row-block > * {
  display: block;
  flex: none;
  float: left;
  width: 100%;
}

.row-block > .col-1 {
  width: 8.333333%;
}

.row-block > .col-2 {
  width: 16.666667%;
}

.row-block > .col-3 {
  width: 25%;
}

.row-block > .col-4 {
  width: 33.333333%;
}

.row-block > .col-5 {
  width: 41.666667%;
}

.row-block > .col-6 {
  width: 50%;
}

.row-block > .col-7 {
  width: 58.333333%;
}

.row-block > .col-8 {
  width: 66.666667%;
}

.row-block > .col-9 {
  width: 75%;
}

.row-block > .col-10 {
  width: 83.333333%;
}

.row-block > .col-11 {
  width: 91.666667%;
}

.row-block > .col-12 {
  width: 100%;
}

@media (min-width: 576px) {
  .row-block > .col-sm-1 {
    width: 8.333333%;
  }
  .row-block > .col-sm-2 {
    width: 16.666667%;
  }
  .row-block > .col-sm-3 {
    width: 25%;
  }
  .row-block > .col-sm-4 {
    width: 33.333333%;
  }
  .row-block > .col-sm-5 {
    width: 41.666667%;
  }
  .row-block > .col-sm-6 {
    width: 50%;
  }
  .row-block > .col-sm-7 {
    width: 58.333333%;
  }
  .row-block > .col-sm-8 {
    width: 66.666667%;
  }
  .row-block > .col-sm-9 {
    width: 75%;
  }
  .row-block > .col-sm-10 {
    width: 83.333333%;
  }
  .row-block > .col-sm-11 {
    width: 91.666667%;
  }
  .row-block > .col-sm-12 {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .row-block > .col-md-1 {
    width: 8.333333%;
  }
  .row-block > .col-md-2 {
    width: 16.666667%;
  }
  .row-block > .col-md-3 {
    width: 25%;
  }
  .row-block > .col-md-4 {
    width: 33.333333%;
  }
  .row-block > .col-md-5 {
    width: 41.666667%;
  }
  .row-block > .col-md-6 {
    width: 50%;
  }
  .row-block > .col-md-7 {
    width: 58.333333%;
  }
  .row-block > .col-md-8 {
    width: 66.666667%;
  }
  .row-block > .col-md-9 {
    width: 75%;
  }
  .row-block > .col-md-10 {
    width: 83.333333%;
  }
  .row-block > .col-md-11 {
    width: 91.666667%;
  }
  .row-block > .col-md-12 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .row-block > .col-lg-1 {
    width: 8.333333%;
  }
  .row-block > .col-lg-2 {
    width: 16.666667%;
  }
  .row-block > .col-lg-3 {
    width: 25%;
  }
  .row-block > .col-lg-4 {
    width: 33.333333%;
  }
  .row-block > .col-lg-5 {
    width: 41.666667%;
  }
  .row-block > .col-lg-6 {
    width: 50%;
  }
  .row-block > .col-lg-7 {
    width: 58.333333%;
  }
  .row-block > .col-lg-8 {
    width: 66.666667%;
  }
  .row-block > .col-lg-9 {
    width: 75%;
  }
  .row-block > .col-lg-10 {
    width: 83.333333%;
  }
  .row-block > .col-lg-11 {
    width: 91.666667%;
  }
  .row-block > .col-lg-12 {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .row-block > .col-xl-1 {
    width: 8.333333%;
  }
  .row-block > .col-xl-2 {
    width: 16.666667%;
  }
  .row-block > .col-xl-3 {
    width: 25%;
  }
  .row-block > .col-xl-4 {
    width: 33.333333%;
  }
  .row-block > .col-xl-5 {
    width: 41.666667%;
  }
  .row-block > .col-xl-6 {
    width: 50%;
  }
  .row-block > .col-xl-7 {
    width: 58.333333%;
  }
  .row-block > .col-xl-8 {
    width: 66.666667%;
  }
  .row-block > .col-xl-9 {
    width: 75%;
  }
  .row-block > .col-xl-10 {
    width: 83.333333%;
  }
  .row-block > .col-xl-11 {
    width: 91.666667%;
  }
  .row-block > .col-xl-12 {
    width: 100%;
  }
}

.col-sbar {
  flex: 0 0 330px;
  max-width: 330px;
}

.col-sbar-sm {
  flex: 0 0 190px;
  max-width: 190px;
}

.col-readerbar {
  flex: 0 0 710px;
  max-width: 710px;
}

@media (min-width: 576px) {
  .row-sm-block {
    display: block !important;
    flex: none;
  }
  .row-sm-block::after {
    display: block;
    clear: both;
    content: "";
  }
  .row-sm-block > * {
    display: block;
    flex: none;
    float: left;
    width: 100%;
  }
  .row-sm-block > .col-1 {
    width: 8.333333%;
  }
  .row-sm-block > .col-2 {
    width: 16.666667%;
  }
  .row-sm-block > .col-3 {
    width: 25%;
  }
  .row-sm-block > .col-4 {
    width: 33.333333%;
  }
  .row-sm-block > .col-5 {
    width: 41.666667%;
  }
  .row-sm-block > .col-6 {
    width: 50%;
  }
  .row-sm-block > .col-7 {
    width: 58.333333%;
  }
  .row-sm-block > .col-8 {
    width: 66.666667%;
  }
  .row-sm-block > .col-9 {
    width: 75%;
  }
  .row-sm-block > .col-10 {
    width: 83.333333%;
  }
  .row-sm-block > .col-11 {
    width: 91.666667%;
  }
  .row-sm-block > .col-12 {
    width: 100%;
  }
}

@media (min-width: 576px) and (min-width: 576px) {
  .row-sm-block > .col-sm-1 {
    width: 8.333333%;
  }
  .row-sm-block > .col-sm-2 {
    width: 16.666667%;
  }
  .row-sm-block > .col-sm-3 {
    width: 25%;
  }
  .row-sm-block > .col-sm-4 {
    width: 33.333333%;
  }
  .row-sm-block > .col-sm-5 {
    width: 41.666667%;
  }
  .row-sm-block > .col-sm-6 {
    width: 50%;
  }
  .row-sm-block > .col-sm-7 {
    width: 58.333333%;
  }
  .row-sm-block > .col-sm-8 {
    width: 66.666667%;
  }
  .row-sm-block > .col-sm-9 {
    width: 75%;
  }
  .row-sm-block > .col-sm-10 {
    width: 83.333333%;
  }
  .row-sm-block > .col-sm-11 {
    width: 91.666667%;
  }
  .row-sm-block > .col-sm-12 {
    width: 100%;
  }
}

@media (min-width: 576px) and (min-width: 768px) {
  .row-sm-block > .col-md-1 {
    width: 8.333333%;
  }
  .row-sm-block > .col-md-2 {
    width: 16.666667%;
  }
  .row-sm-block > .col-md-3 {
    width: 25%;
  }
  .row-sm-block > .col-md-4 {
    width: 33.333333%;
  }
  .row-sm-block > .col-md-5 {
    width: 41.666667%;
  }
  .row-sm-block > .col-md-6 {
    width: 50%;
  }
  .row-sm-block > .col-md-7 {
    width: 58.333333%;
  }
  .row-sm-block > .col-md-8 {
    width: 66.666667%;
  }
  .row-sm-block > .col-md-9 {
    width: 75%;
  }
  .row-sm-block > .col-md-10 {
    width: 83.333333%;
  }
  .row-sm-block > .col-md-11 {
    width: 91.666667%;
  }
  .row-sm-block > .col-md-12 {
    width: 100%;
  }
}

@media (min-width: 576px) and (min-width: 992px) {
  .row-sm-block > .col-lg-1 {
    width: 8.333333%;
  }
  .row-sm-block > .col-lg-2 {
    width: 16.666667%;
  }
  .row-sm-block > .col-lg-3 {
    width: 25%;
  }
  .row-sm-block > .col-lg-4 {
    width: 33.333333%;
  }
  .row-sm-block > .col-lg-5 {
    width: 41.666667%;
  }
  .row-sm-block > .col-lg-6 {
    width: 50%;
  }
  .row-sm-block > .col-lg-7 {
    width: 58.333333%;
  }
  .row-sm-block > .col-lg-8 {
    width: 66.666667%;
  }
  .row-sm-block > .col-lg-9 {
    width: 75%;
  }
  .row-sm-block > .col-lg-10 {
    width: 83.333333%;
  }
  .row-sm-block > .col-lg-11 {
    width: 91.666667%;
  }
  .row-sm-block > .col-lg-12 {
    width: 100%;
  }
}

@media (min-width: 576px) and (min-width: 1200px) {
  .row-sm-block > .col-xl-1 {
    width: 8.333333%;
  }
  .row-sm-block > .col-xl-2 {
    width: 16.666667%;
  }
  .row-sm-block > .col-xl-3 {
    width: 25%;
  }
  .row-sm-block > .col-xl-4 {
    width: 33.333333%;
  }
  .row-sm-block > .col-xl-5 {
    width: 41.666667%;
  }
  .row-sm-block > .col-xl-6 {
    width: 50%;
  }
  .row-sm-block > .col-xl-7 {
    width: 58.333333%;
  }
  .row-sm-block > .col-xl-8 {
    width: 66.666667%;
  }
  .row-sm-block > .col-xl-9 {
    width: 75%;
  }
  .row-sm-block > .col-xl-10 {
    width: 83.333333%;
  }
  .row-sm-block > .col-xl-11 {
    width: 91.666667%;
  }
  .row-sm-block > .col-xl-12 {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .col-sm-sbar {
    flex: 0 0 330px;
    max-width: 330px;
  }
  .col-sm-sbar-sm {
    flex: 0 0 190px;
    max-width: 190px;
  }
  .col-sm-readerbar {
    flex: 0 0 710px;
    max-width: 710px;
  }
}

@media (min-width: 768px) {
  .row-md-block {
    display: block !important;
    flex: none;
  }
  .row-md-block::after {
    display: block;
    clear: both;
    content: "";
  }
  .row-md-block > * {
    display: block;
    flex: none;
    float: left;
    width: 100%;
  }
  .row-md-block > .col-1 {
    width: 8.333333%;
  }
  .row-md-block > .col-2 {
    width: 16.666667%;
  }
  .row-md-block > .col-3 {
    width: 25%;
  }
  .row-md-block > .col-4 {
    width: 33.333333%;
  }
  .row-md-block > .col-5 {
    width: 41.666667%;
  }
  .row-md-block > .col-6 {
    width: 50%;
  }
  .row-md-block > .col-7 {
    width: 58.333333%;
  }
  .row-md-block > .col-8 {
    width: 66.666667%;
  }
  .row-md-block > .col-9 {
    width: 75%;
  }
  .row-md-block > .col-10 {
    width: 83.333333%;
  }
  .row-md-block > .col-11 {
    width: 91.666667%;
  }
  .row-md-block > .col-12 {
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 576px) {
  .row-md-block > .col-sm-1 {
    width: 8.333333%;
  }
  .row-md-block > .col-sm-2 {
    width: 16.666667%;
  }
  .row-md-block > .col-sm-3 {
    width: 25%;
  }
  .row-md-block > .col-sm-4 {
    width: 33.333333%;
  }
  .row-md-block > .col-sm-5 {
    width: 41.666667%;
  }
  .row-md-block > .col-sm-6 {
    width: 50%;
  }
  .row-md-block > .col-sm-7 {
    width: 58.333333%;
  }
  .row-md-block > .col-sm-8 {
    width: 66.666667%;
  }
  .row-md-block > .col-sm-9 {
    width: 75%;
  }
  .row-md-block > .col-sm-10 {
    width: 83.333333%;
  }
  .row-md-block > .col-sm-11 {
    width: 91.666667%;
  }
  .row-md-block > .col-sm-12 {
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .row-md-block > .col-md-1 {
    width: 8.333333%;
  }
  .row-md-block > .col-md-2 {
    width: 16.666667%;
  }
  .row-md-block > .col-md-3 {
    width: 25%;
  }
  .row-md-block > .col-md-4 {
    width: 33.333333%;
  }
  .row-md-block > .col-md-5 {
    width: 41.666667%;
  }
  .row-md-block > .col-md-6 {
    width: 50%;
  }
  .row-md-block > .col-md-7 {
    width: 58.333333%;
  }
  .row-md-block > .col-md-8 {
    width: 66.666667%;
  }
  .row-md-block > .col-md-9 {
    width: 75%;
  }
  .row-md-block > .col-md-10 {
    width: 83.333333%;
  }
  .row-md-block > .col-md-11 {
    width: 91.666667%;
  }
  .row-md-block > .col-md-12 {
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .row-md-block > .col-lg-1 {
    width: 8.333333%;
  }
  .row-md-block > .col-lg-2 {
    width: 16.666667%;
  }
  .row-md-block > .col-lg-3 {
    width: 25%;
  }
  .row-md-block > .col-lg-4 {
    width: 33.333333%;
  }
  .row-md-block > .col-lg-5 {
    width: 41.666667%;
  }
  .row-md-block > .col-lg-6 {
    width: 50%;
  }
  .row-md-block > .col-lg-7 {
    width: 58.333333%;
  }
  .row-md-block > .col-lg-8 {
    width: 66.666667%;
  }
  .row-md-block > .col-lg-9 {
    width: 75%;
  }
  .row-md-block > .col-lg-10 {
    width: 83.333333%;
  }
  .row-md-block > .col-lg-11 {
    width: 91.666667%;
  }
  .row-md-block > .col-lg-12 {
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .row-md-block > .col-xl-1 {
    width: 8.333333%;
  }
  .row-md-block > .col-xl-2 {
    width: 16.666667%;
  }
  .row-md-block > .col-xl-3 {
    width: 25%;
  }
  .row-md-block > .col-xl-4 {
    width: 33.333333%;
  }
  .row-md-block > .col-xl-5 {
    width: 41.666667%;
  }
  .row-md-block > .col-xl-6 {
    width: 50%;
  }
  .row-md-block > .col-xl-7 {
    width: 58.333333%;
  }
  .row-md-block > .col-xl-8 {
    width: 66.666667%;
  }
  .row-md-block > .col-xl-9 {
    width: 75%;
  }
  .row-md-block > .col-xl-10 {
    width: 83.333333%;
  }
  .row-md-block > .col-xl-11 {
    width: 91.666667%;
  }
  .row-md-block > .col-xl-12 {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-sbar {
    flex: 0 0 330px;
    max-width: 330px;
  }
  .col-md-sbar-sm {
    flex: 0 0 190px;
    max-width: 190px;
  }
  .col-md-readerbar {
    flex: 0 0 710px;
    max-width: 710px;
  }
}

@media (min-width: 992px) {
  .row-lg-block {
    display: block !important;
    flex: none;
  }
  .row-lg-block::after {
    display: block;
    clear: both;
    content: "";
  }
  .row-lg-block > * {
    display: block;
    flex: none;
    float: left;
    width: 100%;
  }
  .row-lg-block > .col-1 {
    width: 8.333333%;
  }
  .row-lg-block > .col-2 {
    width: 16.666667%;
  }
  .row-lg-block > .col-3 {
    width: 25%;
  }
  .row-lg-block > .col-4 {
    width: 33.333333%;
  }
  .row-lg-block > .col-5 {
    width: 41.666667%;
  }
  .row-lg-block > .col-6 {
    width: 50%;
  }
  .row-lg-block > .col-7 {
    width: 58.333333%;
  }
  .row-lg-block > .col-8 {
    width: 66.666667%;
  }
  .row-lg-block > .col-9 {
    width: 75%;
  }
  .row-lg-block > .col-10 {
    width: 83.333333%;
  }
  .row-lg-block > .col-11 {
    width: 91.666667%;
  }
  .row-lg-block > .col-12 {
    width: 100%;
  }
}

@media (min-width: 992px) and (min-width: 576px) {
  .row-lg-block > .col-sm-1 {
    width: 8.333333%;
  }
  .row-lg-block > .col-sm-2 {
    width: 16.666667%;
  }
  .row-lg-block > .col-sm-3 {
    width: 25%;
  }
  .row-lg-block > .col-sm-4 {
    width: 33.333333%;
  }
  .row-lg-block > .col-sm-5 {
    width: 41.666667%;
  }
  .row-lg-block > .col-sm-6 {
    width: 50%;
  }
  .row-lg-block > .col-sm-7 {
    width: 58.333333%;
  }
  .row-lg-block > .col-sm-8 {
    width: 66.666667%;
  }
  .row-lg-block > .col-sm-9 {
    width: 75%;
  }
  .row-lg-block > .col-sm-10 {
    width: 83.333333%;
  }
  .row-lg-block > .col-sm-11 {
    width: 91.666667%;
  }
  .row-lg-block > .col-sm-12 {
    width: 100%;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .row-lg-block > .col-md-1 {
    width: 8.333333%;
  }
  .row-lg-block > .col-md-2 {
    width: 16.666667%;
  }
  .row-lg-block > .col-md-3 {
    width: 25%;
  }
  .row-lg-block > .col-md-4 {
    width: 33.333333%;
  }
  .row-lg-block > .col-md-5 {
    width: 41.666667%;
  }
  .row-lg-block > .col-md-6 {
    width: 50%;
  }
  .row-lg-block > .col-md-7 {
    width: 58.333333%;
  }
  .row-lg-block > .col-md-8 {
    width: 66.666667%;
  }
  .row-lg-block > .col-md-9 {
    width: 75%;
  }
  .row-lg-block > .col-md-10 {
    width: 83.333333%;
  }
  .row-lg-block > .col-md-11 {
    width: 91.666667%;
  }
  .row-lg-block > .col-md-12 {
    width: 100%;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .row-lg-block > .col-lg-1 {
    width: 8.333333%;
  }
  .row-lg-block > .col-lg-2 {
    width: 16.666667%;
  }
  .row-lg-block > .col-lg-3 {
    width: 25%;
  }
  .row-lg-block > .col-lg-4 {
    width: 33.333333%;
  }
  .row-lg-block > .col-lg-5 {
    width: 41.666667%;
  }
  .row-lg-block > .col-lg-6 {
    width: 50%;
  }
  .row-lg-block > .col-lg-7 {
    width: 58.333333%;
  }
  .row-lg-block > .col-lg-8 {
    width: 66.666667%;
  }
  .row-lg-block > .col-lg-9 {
    width: 75%;
  }
  .row-lg-block > .col-lg-10 {
    width: 83.333333%;
  }
  .row-lg-block > .col-lg-11 {
    width: 91.666667%;
  }
  .row-lg-block > .col-lg-12 {
    width: 100%;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .row-lg-block > .col-xl-1 {
    width: 8.333333%;
  }
  .row-lg-block > .col-xl-2 {
    width: 16.666667%;
  }
  .row-lg-block > .col-xl-3 {
    width: 25%;
  }
  .row-lg-block > .col-xl-4 {
    width: 33.333333%;
  }
  .row-lg-block > .col-xl-5 {
    width: 41.666667%;
  }
  .row-lg-block > .col-xl-6 {
    width: 50%;
  }
  .row-lg-block > .col-xl-7 {
    width: 58.333333%;
  }
  .row-lg-block > .col-xl-8 {
    width: 66.666667%;
  }
  .row-lg-block > .col-xl-9 {
    width: 75%;
  }
  .row-lg-block > .col-xl-10 {
    width: 83.333333%;
  }
  .row-lg-block > .col-xl-11 {
    width: 91.666667%;
  }
  .row-lg-block > .col-xl-12 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-sbar {
    flex: 0 0 330px;
    max-width: 330px;
  }
  .col-lg-sbar-sm {
    flex: 0 0 190px;
    max-width: 190px;
  }
  .col-lg-readerbar {
    flex: 0 0 710px;
    max-width: 710px;
  }
}

@media (min-width: 1200px) {
  .row-xl-block {
    display: block !important;
    flex: none;
  }
  .row-xl-block::after {
    display: block;
    clear: both;
    content: "";
  }
  .row-xl-block > * {
    display: block;
    flex: none;
    float: left;
    width: 100%;
  }
  .row-xl-block > .col-1 {
    width: 8.333333%;
  }
  .row-xl-block > .col-2 {
    width: 16.666667%;
  }
  .row-xl-block > .col-3 {
    width: 25%;
  }
  .row-xl-block > .col-4 {
    width: 33.333333%;
  }
  .row-xl-block > .col-5 {
    width: 41.666667%;
  }
  .row-xl-block > .col-6 {
    width: 50%;
  }
  .row-xl-block > .col-7 {
    width: 58.333333%;
  }
  .row-xl-block > .col-8 {
    width: 66.666667%;
  }
  .row-xl-block > .col-9 {
    width: 75%;
  }
  .row-xl-block > .col-10 {
    width: 83.333333%;
  }
  .row-xl-block > .col-11 {
    width: 91.666667%;
  }
  .row-xl-block > .col-12 {
    width: 100%;
  }
}

@media (min-width: 1200px) and (min-width: 576px) {
  .row-xl-block > .col-sm-1 {
    width: 8.333333%;
  }
  .row-xl-block > .col-sm-2 {
    width: 16.666667%;
  }
  .row-xl-block > .col-sm-3 {
    width: 25%;
  }
  .row-xl-block > .col-sm-4 {
    width: 33.333333%;
  }
  .row-xl-block > .col-sm-5 {
    width: 41.666667%;
  }
  .row-xl-block > .col-sm-6 {
    width: 50%;
  }
  .row-xl-block > .col-sm-7 {
    width: 58.333333%;
  }
  .row-xl-block > .col-sm-8 {
    width: 66.666667%;
  }
  .row-xl-block > .col-sm-9 {
    width: 75%;
  }
  .row-xl-block > .col-sm-10 {
    width: 83.333333%;
  }
  .row-xl-block > .col-sm-11 {
    width: 91.666667%;
  }
  .row-xl-block > .col-sm-12 {
    width: 100%;
  }
}

@media (min-width: 1200px) and (min-width: 768px) {
  .row-xl-block > .col-md-1 {
    width: 8.333333%;
  }
  .row-xl-block > .col-md-2 {
    width: 16.666667%;
  }
  .row-xl-block > .col-md-3 {
    width: 25%;
  }
  .row-xl-block > .col-md-4 {
    width: 33.333333%;
  }
  .row-xl-block > .col-md-5 {
    width: 41.666667%;
  }
  .row-xl-block > .col-md-6 {
    width: 50%;
  }
  .row-xl-block > .col-md-7 {
    width: 58.333333%;
  }
  .row-xl-block > .col-md-8 {
    width: 66.666667%;
  }
  .row-xl-block > .col-md-9 {
    width: 75%;
  }
  .row-xl-block > .col-md-10 {
    width: 83.333333%;
  }
  .row-xl-block > .col-md-11 {
    width: 91.666667%;
  }
  .row-xl-block > .col-md-12 {
    width: 100%;
  }
}

@media (min-width: 1200px) and (min-width: 992px) {
  .row-xl-block > .col-lg-1 {
    width: 8.333333%;
  }
  .row-xl-block > .col-lg-2 {
    width: 16.666667%;
  }
  .row-xl-block > .col-lg-3 {
    width: 25%;
  }
  .row-xl-block > .col-lg-4 {
    width: 33.333333%;
  }
  .row-xl-block > .col-lg-5 {
    width: 41.666667%;
  }
  .row-xl-block > .col-lg-6 {
    width: 50%;
  }
  .row-xl-block > .col-lg-7 {
    width: 58.333333%;
  }
  .row-xl-block > .col-lg-8 {
    width: 66.666667%;
  }
  .row-xl-block > .col-lg-9 {
    width: 75%;
  }
  .row-xl-block > .col-lg-10 {
    width: 83.333333%;
  }
  .row-xl-block > .col-lg-11 {
    width: 91.666667%;
  }
  .row-xl-block > .col-lg-12 {
    width: 100%;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .row-xl-block > .col-xl-1 {
    width: 8.333333%;
  }
  .row-xl-block > .col-xl-2 {
    width: 16.666667%;
  }
  .row-xl-block > .col-xl-3 {
    width: 25%;
  }
  .row-xl-block > .col-xl-4 {
    width: 33.333333%;
  }
  .row-xl-block > .col-xl-5 {
    width: 41.666667%;
  }
  .row-xl-block > .col-xl-6 {
    width: 50%;
  }
  .row-xl-block > .col-xl-7 {
    width: 58.333333%;
  }
  .row-xl-block > .col-xl-8 {
    width: 66.666667%;
  }
  .row-xl-block > .col-xl-9 {
    width: 75%;
  }
  .row-xl-block > .col-xl-10 {
    width: 83.333333%;
  }
  .row-xl-block > .col-xl-11 {
    width: 91.666667%;
  }
  .row-xl-block > .col-xl-12 {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl-sbar {
    flex: 0 0 330px;
    max-width: 330px;
  }
  .col-xl-sbar-sm {
    flex: 0 0 190px;
    max-width: 190px;
  }
  .col-xl-readerbar {
    flex: 0 0 710px;
    max-width: 710px;
  }
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cbd9fe;
}

.table-hover .table-primary:hover {
  background-color: #b2c7fe;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b2c7fe;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dee0e6;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2db;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd2db;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bfc0c3;
}

.table-hover .table-dark:hover {
  background-color: #b2b3b7;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b2b3b7;
}

.form-control:focus {
  border-color: #c3d3fd;
  box-shadow: 0 0 0 0.2rem rgba(71, 119, 250, 0.25);
}

.form-label {
  cursor: pointer;
}

.form-control-transparent {
  padding-right: 0;
  padding-left: 0;
}

.form-control-transparent, .form-control-transparent:focus {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
}

.navbar-light .form-control-transparent {
  color: rgba(27, 31, 40, 0.6);
  border-color: rgba(52, 58, 64, 0.1);
}

.navbar-light .form-control-transparent:focus {
  color: #4777fa;
  border-color: #4777fa;
}

.navbar-light .form-control-transparent:focus + .nav-link {
  color: #4777fa;
}

.navbar-dark .form-control-transparent {
  color: rgba(255, 255, 255, 0.6);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .form-control-transparent:focus {
  color: #4777fa;
  border-color: #4777fa;
}

.navbar-dark .form-control-transparent:focus + .nav-link {
  color: #4777fa;
}

.form-control-search {
  width: 0;
  transition: all .2s ease-out;
}

.form-control-search:focus {
  width: 100%;
}

.btn:focus, .btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 119, 250, 0.25);
}

.btn-primary {
  color: #fff;
  background-color: #4777fa;
  border-color: #4777fa;
}

.btn-primary:hover {
  color: #fff;
  background-color: #225bf9;
  border-color: #1552f9;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 139, 251, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #4777fa;
  border-color: #4777fa;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1552f9;
  border-color: #0949f8;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 139, 251, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #8a92a5;
  border-color: #8a92a5;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #747e94;
  border-color: #6e778e;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 162, 179, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #8a92a5;
  border-color: #8a92a5;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6e778e;
  border-color: #687187;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 162, 179, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #1b1f28;
  border-color: #1b1f28;
}

.btn-dark:hover {
  color: #fff;
  background-color: #0c0d11;
  border-color: #06070a;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 65, 72, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #1b1f28;
  border-color: #1b1f28;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #06070a;
  border-color: #010202;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 65, 72, 0.5);
}

.btn-outline-primary {
  color: #4777fa;
  border-color: #4777fa;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #4777fa;
  border-color: #4777fa;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 119, 250, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4777fa;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4777fa;
  border-color: #4777fa;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 119, 250, 0.5);
}

.btn-outline-secondary {
  color: #8a92a5;
  border-color: #8a92a5;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #8a92a5;
  border-color: #8a92a5;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 146, 165, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #8a92a5;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #8a92a5;
  border-color: #8a92a5;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 146, 165, 0.5);
}

.btn-outline-dark {
  color: #1b1f28;
  border-color: #1b1f28;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #1b1f28;
  border-color: #1b1f28;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 31, 40, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #1b1f28;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #1b1f28;
  border-color: #1b1f28;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 31, 40, 0.5);
}

.btn-link {
  color: #1b1f28;
}

.btn-link:hover {
  color: #4777fa;
  text-decoration: none;
}

.btn-hover-primary:hover,
.btn-hover-primary:focus,
.btn-hover-primary.focus,
.btn-hover-primary:not(:disabled):not(.disabled):active,
.btn-hover-primary:not(:disabled):not(.disabled).active,
.btn-hover-outline-primary:focus,
.btn-hover-outline-primary.focus,
.btn-hover-outline-primary:not(:disabled):not(.disabled):active,
.btn-hover-outline-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #4777fa;
  border-color: #4777fa;
  box-shadow: none;
}

.btn-hover-outline-primary:hover {
  color: #4777fa;
  background-color: transparent;
  border-color: #4777fa;
}

.collapse.show ~ .collapsed-info {
  display: none;
}

.collapse:not(.show) ~ [data-toggle="collapse"] .fas::before {
  content: "";
}

.dropdown-menu {
  margin: -2px 0 0;
}

.dropup .dropdown-menu {
  margin-bottom: -2px;
}

.dropright .dropdown-menu {
  margin-left: -2px;
}

.dropleft .dropdown-menu {
  margin-right: -2px;
}

.dropdown-item {
  color: rgba(27, 31, 40, 0.6);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #4777fa;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #4777fa;
}

.dropdown-item-text {
  color: rgba(27, 31, 40, 0.6);
}

.header .dropdown-menu {
  z-index: 1030;
  margin-right: -1rem;
  margin-left: -1rem;
}

.header .dropdown-item:hover, .header .dropdown-item:focus {
  background-color: transparent;
}

.header .dropdown-item.active, .header .dropdown-item:active {
  color: #4777fa;
  background-color: transparent;
}

.dropdown.allow-hover:hover > .dropdown-menu, .dropdown.allow-hover:focus > .dropdown-menu {
  display: block;
}

.dropdown-item {
  margin-bottom: 0;
}

.dropdown-item .row {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}

.dropdown--mainmenu {
  /* stylelint-disable */
  /* stylelint-enable */
}

.dropdown--mainmenu > .dropdown-menu {
  min-width: 300px;
}

.navbar .dropdown--mainmenu > .dropdown-toggle {
  position: relative;
  z-index: 1001;
  border: 1px solid transparent;
}

.navbar .dropdown--mainmenu.show > .nav-link {
  background-clip: padding-box;
}

.dropdown--mainmenu > ul > li > a.dropdown-item {
  font-size: 1.25rem;
  line-height: 1.5;
}

.mainmenu-close {
  position: absolute;
  top: 15px;
  right: 15px;
  left: auto;
  font-size: 1.25rem;
}

.dropdown--mega {
  position: initial;
}

.dropdown--mega .dropdown-menu {
  min-width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.container .dropdown--mega .dropdown-menu {
  right: -15px;
  left: -15px;
}

.dropdown-toggle.disable-caret::after {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
  content: inherit !important;
  /* stylelint-disable-line declaration-no-important */
}

.dropdown .dropdown {
  position: relative;
}

.dropdown .dropdown > a::after {
  float: right;
  content: "";
}

.dropdown .dropdown > .dropdown-menu {
  top: -0.5rem;
  left: 100%;
  margin-top: -1px;
  margin-left: 0;
}

@media (max-width: 575.98px) {
  .mainnav-active {
    overflow: hidden;
  }
  #header,
  #main,
  #footer,
  .section {
    min-width: 100%;
    transition: all .5s ease-out;
  }
  .mainnav-active > #header, .mainnav-active >
  #main, .mainnav-active >
  #footer, .mainnav-active >
  .section {
    margin-left: 100%;
  }
  #mainNav > .dropdown-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    z-index: 1050;
    display: block;
    width: 100%;
    min-width: initial;
    overflow: auto;
    transition: all .4s ease-out;
  }
  .mainnav-active #mainNav > .dropdown-menu {
    left: 0;
    transition: all .6s ease-out;
  }
  .navbar-nav #mainNav .dropdown-menu.show {
    display: block;
  }
  #mainNav .dropdown > .dropdown-menu {
    position: relative;
    top: initial;
    right: initial;
    bottom: initial;
    left: initial;
    margin-left: initial;
    border-right: none;
    border-bottom: none;
    border-left: none;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4777fa;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(71, 119, 250, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: #f5f7ff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4777fa;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #4777fa;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(71, 119, 250, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(71, 119, 250, 0.5);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4777fa;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(71, 119, 250, 0.5);
}

.custom-select:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 119, 250, 0.25);
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(71, 119, 250, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(71, 119, 250, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(71, 119, 250, 0.25);
}

.custom-range::-webkit-slider-thumb {
  background-color: #4777fa;
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #f5f7ff;
}

.custom-range::-moz-range-thumb:active {
  background-color: #f5f7ff;
}

.custom-range::-ms-thumb {
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.custom-range::-ms-thumb:active {
  background-color: #f5f7ff;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4777fa;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #4777fa;
}

.navbar-light .navbar-brand {
  color: #4777fa;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #4777fa;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(27, 31, 40, 0.6);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #4777fa;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #4777fa;
}

.navbar-light .navbar-toggler {
  color: rgba(27, 31, 40, 0.6);
}

.navbar-light .navbar-text {
  color: rgba(27, 31, 40, 0.6);
}

.navbar-light .navbar-text a {
  color: #4777fa;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #4777fa;
}

.navbar-dark .navbar-brand {
  color: #4777fa;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #4777fa;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #4777fa;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #4777fa;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .navbar-text a {
  color: #4777fa;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #4777fa;
}

.navbar-collapse {
  padding-right: calc(15px - 0.5rem);
  padding-left: calc(15px - 0.5rem);
}

.page-link {
  color: #1b1f28;
  background-color: transparent;
}

.page-link:hover {
  color: #4777fa;
  background-color: transparent;
  border-color: #4777fa;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 119, 250, 0.25);
}

.page-item.active .page-link {
  background-color: #4777fa;
  border-color: #4777fa;
}

.badge-primary {
  color: #fff;
  background-color: #4777fa;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #1552f9;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 119, 250, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #8a92a5;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #6e778e;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(138, 146, 165, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #1b1f28;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #06070a;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(27, 31, 40, 0.5);
}

.alert-primary {
  color: #253e82;
  background-color: #dae4fe;
  border-color: #cbd9fe;
}

.alert-primary hr {
  border-top-color: #b2c7fe;
}

.alert-primary .alert-link {
  color: #1a2b5a;
}

.alert-secondary {
  color: #484c56;
  background-color: #e8e9ed;
  border-color: #dee0e6;
}

.alert-secondary hr {
  border-top-color: #cfd2db;
}

.alert-secondary .alert-link {
  color: #31333a;
}

.alert-dark {
  color: #0e1015;
  background-color: #d1d2d4;
  border-color: #bfc0c3;
}

.alert-dark hr {
  border-top-color: #b2b3b7;
}

.alert-dark .alert-link {
  color: black;
}

.progress-bar {
  background-color: #4777fa;
}

.list-group-item.active {
  background-color: #4777fa;
}

.list-group-item-primary {
  color: #253e82;
  background-color: #cbd9fe;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #253e82;
  background-color: #b2c7fe;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #253e82;
  border-color: #253e82;
}

.list-group-item-secondary {
  color: #484c56;
  background-color: #dee0e6;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #484c56;
  background-color: #cfd2db;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #484c56;
  border-color: #484c56;
}

.list-group-item-dark {
  color: #0e1015;
  background-color: #bfc0c3;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0e1015;
  background-color: #b2b3b7;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0e1015;
  border-color: #0e1015;
}

.tooltip {
  font-family: "Source Sans Pro", sans-serif;
}

.popover {
  font-family: "Source Sans Pro", sans-serif;
}

.carousel-control-next,
.carousel-control-prev {
  width: 32px;
  opacity: 0.5;
}

.carousel-inner {
  width: auto;
  min-width: 100%;
}

.section > .carousel-inner {
  margin-right: -15px;
  margin-left: -15px;
}

.section > .carousel-inner > .carousel-item {
  padding-right: 15px;
  padding-left: 15px;
}

.carousel-control-next,
.carousel-control-prev {
  top: 50%;
  z-index: 10;
  height: 32px;
  margin-top: -16px;
  line-height: 20px;
  color: #1b1f28 !important;
  background-color: #fff;
  border: 1px solid rgba(52, 58, 64, 0.1);
}

.section > .carousel-control-next, .section >
.carousel-control-prev {
  margin-top: -24px;
}

.carousel:hover .carousel-control-next, .carousel:hover
.carousel-control-prev {
  opacity: 1;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  color: #4777fa !important;
  border-color: #4777fa !important;
}

.carousel-control-next:focus,
.carousel-control-prev:focus {
  color: #fff !important;
  background-color: #4777fa !important;
}

.carousel-control-next {
  margin-right: -15px;
}

.section > .carousel-control-next {
  margin-right: 0;
}

.carousel-control-prev {
  margin-left: -15px;
}

.section > .carousel-control-prev {
  margin-left: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
}

.carousel-control-next-icon::before {
  font-family: "Font Awesome 5 Free", serif;
  font-style: normal;
  font-weight: 900;
  content: "";
}

.carousel-control-prev-icon::before {
  font-family: "Font Awesome 5 Free", serif;
  font-style: normal;
  font-weight: 900;
  content: "";
}

.carousel-indicators .active {
  background-color: #4777fa;
}

.carousel--post-thumbnail .carousel-indicators {
  bottom: 1rem;
}

.carousel--post-thumbnail.with-image-caption .carousel-indicators {
  bottom: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bg {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.bg-overlapped > .bg {
  top: 210px;
  bottom: -210px;
}

.bg-overlappedt > .bg {
  top: 210px;
}

.bg-overlappedb > .bg {
  bottom: -210px;
}

.bg-overlapped-sm > .bg {
  top: 30px;
  bottom: -30px;
}

.bg-overlappedt-sm > .bg {
  top: 30px;
}

.bg-overlappedb-sm > .bg {
  bottom: -30px;
}

.bg-img-wrapper {
  position: relative;
  overflow: hidden;
}

.bg-img-wrapper .bg-img {
  width: 100%;
  height: 100%;
}

.bg-pull-left {
  left: auto;
  width: 100vw;
  margin-left: auto;
}

.bg-pull-right {
  right: auto;
  width: 100vw;
  margin-right: auto;
}

.bg-img {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}

.bg-img .img {
  position: relative;
  width: 100%;
  visibility: hidden;
}

.bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

[class^="pattern-"],
[class*=" pattern"] {
  background-repeat: repeat;
  background-size: initial;
}

.col-bg {
  margin-right: -15px;
  margin-left: -15px;
}

.overlay {
  position: absolute !important;
  /* stylelint-disable-line declaration-no-important */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-t,
.overlay-t-l,
.overlay-t-r {
  top: 0;
  bottom: auto;
}

.overlay-b,
.overlay-b-l,
.overlay-b-r {
  top: auto;
  bottom: 0;
}

.overlay-l,
.overlay-t-l,
.overlay-b-l {
  right: auto;
  left: 0;
}

.overlay-r,
.overlay-t-r,
.overlay-b-r {
  right: 0;
  left: auto;
}

.overlay[class^="bg-gradient-b-"],
.overlay[class*=" bg-gradient-b-"] {
  top: auto;
  bottom: 0;
  height: 30%;
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
}

.header .nav-item:hover {
  z-index: 1030;
}

.header .dropdown-item,
.header .nav-link {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.section .section {
  margin-right: -15px;
  margin-left: -15px;
}

.row > .section {
  margin-right: 0;
  margin-left: 0;
}

.section-img {
  position: relative;
  z-index: 2;
}

.section-header {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1.1rem;
  padding-bottom: 1rem;
}

.section-title {
  margin: 0;
  font-weight: 600;
}

.section-nav-tabs {
  border: none;
}

.section-nav-tabs .nav-item {
  margin-bottom: 0;
  font-size: .95rem;
}

.section-nav-tabs .nav-link {
  padding: 0;
  border: none;
}

.section-nav-tabs .nav-link.active {
  background-color: transparent;
}

.section-nav-tabs .nav-link ~ .nav-link {
  margin-left: 1rem;
}

.section-footer {
  position: relative;
  z-index: 2;
}

.section-light h1,
.section-light h1 a, .section-light h2,
.section-light h2 a, .section-light h3,
.section-light h3 a, .section-light h4,
.section-light h4 a, .section-light h5,
.section-light h5 a, .section-light h6,
.section-light h6 a, .section-light .h1,
.section-light .h1 a, .section-light .h2,
.section-light .h2 a, .section-light .h3,
.section-light .h3 a, .section-light .h4,
.section-light .h4 a, .section-light .h5,
.section-light .h5 a, .section-light .h6,
.section-light .h6 a {
  color: #1b1f28;
}

.section-light h1 a:hover, .section-light h1 a:focus, .section-light h2 a:hover, .section-light h2 a:focus, .section-light h3 a:hover, .section-light h3 a:focus, .section-light h4 a:hover, .section-light h4 a:focus, .section-light h5 a:hover, .section-light h5 a:focus, .section-light h6 a:hover, .section-light h6 a:focus, .section-light .h1 a:hover, .section-light .h1 a:focus, .section-light .h2 a:hover, .section-light .h2 a:focus, .section-light .h3 a:hover, .section-light .h3 a:focus, .section-light .h4 a:hover, .section-light .h4 a:focus, .section-light .h5 a:hover, .section-light .h5 a:focus, .section-light .h6 a:hover, .section-light .h6 a:focus {
  color: #4777fa;
}

.section-light.section--footer {
  color: #8a92a5;
}

.section-light.section--footer a {
  color: #8a92a5;
}

.section-light.section--footer a:hover, .section-light.section--footer a:focus {
  color: #4777fa;
}

.section-light > .section-header,
.section-light .row > .section-header {
  color: #1b1f28;
}

.section-light > .section-header .section-title,
.section-light .row > .section-header .section-title {
  color: #1b1f28;
}

.section-light > .section-header .section-title a,
.section-light .row > .section-header .section-title a {
  color: #1b1f28;
}

.section-light > .section-header .section-title a:hover, .section-light > .section-header .section-title a:focus,
.section-light .row > .section-header .section-title a:hover,
.section-light .row > .section-header .section-title a:focus {
  color: #6089fb;
}

.section-light > .section-header .nav,
.section-light .row > .section-header .nav {
  color: #8a92a5;
}

.section-light > .section-header .nav-link:not(.active),
.section-light .row > .section-header .nav-link:not(.active) {
  color: #8a92a5 !important;
}

.section-light > .section-header .nav-link:hover, .section-light > .section-header .nav-link:focus,
.section-light .row > .section-header .nav-link:hover,
.section-light .row > .section-header .nav-link:focus {
  color: #4777fa;
}

.section-light > .section-header .nav-link.active,
.section-light .row > .section-header .nav-link.active {
  color: #4777fa;
}

.section-dark {
  color: #f7f7f7;
}

.section-dark a {
  color: #f7f7f7;
}

.section-dark a:hover, .section-dark a:focus {
  color: #4777fa;
}

.section-dark h1,
.section-dark h1 a, .section-dark h2,
.section-dark h2 a, .section-dark h3,
.section-dark h3 a, .section-dark h4,
.section-dark h4 a, .section-dark h5,
.section-dark h5 a, .section-dark h6,
.section-dark h6 a, .section-dark .h1,
.section-dark .h1 a, .section-dark .h2,
.section-dark .h2 a, .section-dark .h3,
.section-dark .h3 a, .section-dark .h4,
.section-dark .h4 a, .section-dark .h5,
.section-dark .h5 a, .section-dark .h6,
.section-dark .h6 a {
  color: #fff;
}

.section-dark h1 a:hover, .section-dark h1 a:focus, .section-dark h2 a:hover, .section-dark h2 a:focus, .section-dark h3 a:hover, .section-dark h3 a:focus, .section-dark h4 a:hover, .section-dark h4 a:focus, .section-dark h5 a:hover, .section-dark h5 a:focus, .section-dark h6 a:hover, .section-dark h6 a:focus, .section-dark .h1 a:hover, .section-dark .h1 a:focus, .section-dark .h2 a:hover, .section-dark .h2 a:focus, .section-dark .h3 a:hover, .section-dark .h3 a:focus, .section-dark .h4 a:hover, .section-dark .h4 a:focus, .section-dark .h5 a:hover, .section-dark .h5 a:focus, .section-dark .h6 a:hover, .section-dark .h6 a:focus {
  color: #4777fa;
}

.section-dark.section--footer {
  color: #8a92a5;
}

.section-dark.section--footer a {
  color: #8a92a5;
}

.section-dark.section--footer a:hover, .section-dark.section--footer a:focus {
  color: #4777fa;
}

.section-dark > .section-header,
.section-dark .row > .section-header {
  color: #fff;
}

.section-dark > .section-header .section-title,
.section-dark .row > .section-header .section-title {
  color: #fff;
}

.section-dark > .section-header .section-title a,
.section-dark .row > .section-header .section-title a {
  color: #fff;
}

.section-dark > .section-header .section-title a:hover, .section-dark > .section-header .section-title a:focus,
.section-dark .row > .section-header .section-title a:hover,
.section-dark .row > .section-header .section-title a:focus {
  color: #fff;
}

.section-dark > .section-header .nav,
.section-dark .row > .section-header .nav {
  color: #ced4da;
}

.section-dark > .section-header .nav-link:not(.active),
.section-dark .row > .section-header .nav-link:not(.active) {
  color: #ced4da !important;
}

.section-dark > .section-header .nav-link:hover, .section-dark > .section-header .nav-link:focus,
.section-dark .row > .section-header .nav-link:hover,
.section-dark .row > .section-header .nav-link:focus {
  color: #fff;
}

.section-dark > .section-header .nav-link.active,
.section-dark .row > .section-header .nav-link.active {
  color: #fff;
}

.posts {
  margin-top: -15px;
}

.posts.row > .section,
.posts.row > *:not(.post) > .section {
  margin-top: 15px;
}

.posts .post {
  margin-top: 0;
}

.post {
  position: relative;
  display: flex;
  flex-direction: column;
}

.post:hover .post-overlay-link {
  z-index: 1;
}

.post-summary .post-overlay-link {
  top: 15px;
  bottom: 15px;
}

.row .post-overlay-link {
  right: 15px;
  left: 15px;
}

.post-summary {
  padding-top: 15px;
  padding-bottom: 15px;
}

.post-bg-overlay {
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
}

.row > .post > .post-bg-overlay {
  padding-right: 15px;
  padding-left: 15px;
}

.post.gutter {
  padding-top: 15px;
  padding-bottom: 15px;
}

.post.gutter > .post-bg-overlay {
  padding-top: 15px;
  padding-bottom: 15px;
}

.row > .post.gutter,
.row > .post.gutter > .post-bg-overlay {
  padding-right: 15px;
  padding-left: 15px;
}

.post.guttert {
  padding-top: 15px;
}

.post.guttert > .post-bg-overlay {
  padding-top: 15px;
}

.post.gutterb {
  padding-bottom: 15px;
}

.post.gutterb > .post-bg-overlay {
  padding-bottom: 15px;
}

.row > .post.gutterx,
.row > .post.gutterx > .post-bg-overlay, .row >
.post.gutterr,
.row >
.post.gutterr > .post-bg-overlay {
  padding-right: 15px;
}

.row > .post.gutterx,
.row > .post.gutterx > .post-bg-overlay, .row >
.post.gutterl,
.row >
.post.gutterl > .post-bg-overlay {
  padding-left: 15px;
}

.post.no-gutter {
  padding-top: 0;
  padding-bottom: 0;
}

.post.no-gutter > .post-bg-overlay {
  padding-top: 0;
  padding-bottom: 0;
}

.row > .post.no-gutter,
.row > .post.no-gutter > .post-bg-overlay {
  padding-right: 0;
  padding-left: 0;
}

.post.no-guttert {
  padding-top: 0;
}

.post.no-guttert > .post-bg-overlay {
  padding-top: 0;
}

.post.no-gutterb {
  padding-bottom: 0;
}

.post.no-gutterb > .post-bg-overlay {
  padding-bottom: 0;
}

.row > .post.no-gutterx,
.row > .post.no-gutterx > .post-bg-overlay, .row >
.post.no-gutterr,
.row >
.post.no-gutterr > .post-bg-overlay {
  padding-right: 0;
}

.row > .post.no-gutterx,
.row > .post.no-gutterx > .post-bg-overlay, .row >
.post.no-gutterl,
.row >
.post.no-gutterl > .post-bg-overlay {
  padding-left: 0;
}

@media (min-width: 576px) {
  .post.gutter-sm {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .post.gutter-sm > .post-bg-overlay {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .row > .post.gutter-sm,
  .row > .post.gutter-sm > .post-bg-overlay {
    padding-right: 15px;
    padding-left: 15px;
  }
  .post.guttert-sm {
    padding-top: 15px;
  }
  .post.guttert-sm > .post-bg-overlay {
    padding-top: 15px;
  }
  .post.gutterb-sm {
    padding-bottom: 15px;
  }
  .post.gutterb-sm > .post-bg-overlay {
    padding-bottom: 15px;
  }
  .row > .post.gutterx-sm,
  .row > .post.gutterx-sm > .post-bg-overlay, .row >
  .post.gutterr-sm,
  .row >
  .post.gutterr-sm > .post-bg-overlay {
    padding-right: 15px;
  }
  .row > .post.gutterx-sm,
  .row > .post.gutterx-sm > .post-bg-overlay, .row >
  .post.gutterl-sm,
  .row >
  .post.gutterl-sm > .post-bg-overlay {
    padding-left: 15px;
  }
  .post.no-gutter-sm {
    padding-top: 0;
    padding-bottom: 0;
  }
  .post.no-gutter-sm > .post-bg-overlay {
    padding-top: 0;
    padding-bottom: 0;
  }
  .row > .post.no-gutter-sm,
  .row > .post.no-gutter-sm > .post-bg-overlay {
    padding-right: 0;
    padding-left: 0;
  }
  .post.no-guttert-sm {
    padding-top: 0;
  }
  .post.no-guttert-sm > .post-bg-overlay {
    padding-top: 0;
  }
  .post.no-gutterb-sm {
    padding-bottom: 0;
  }
  .post.no-gutterb-sm > .post-bg-overlay {
    padding-bottom: 0;
  }
  .row > .post.no-gutterx-sm,
  .row > .post.no-gutterx-sm > .post-bg-overlay, .row >
  .post.no-gutterr-sm,
  .row >
  .post.no-gutterr-sm > .post-bg-overlay {
    padding-right: 0;
  }
  .row > .post.no-gutterx-sm,
  .row > .post.no-gutterx-sm > .post-bg-overlay, .row >
  .post.no-gutterl-sm,
  .row >
  .post.no-gutterl-sm > .post-bg-overlay {
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .post.gutter-md {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .post.gutter-md > .post-bg-overlay {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .row > .post.gutter-md,
  .row > .post.gutter-md > .post-bg-overlay {
    padding-right: 15px;
    padding-left: 15px;
  }
  .post.guttert-md {
    padding-top: 15px;
  }
  .post.guttert-md > .post-bg-overlay {
    padding-top: 15px;
  }
  .post.gutterb-md {
    padding-bottom: 15px;
  }
  .post.gutterb-md > .post-bg-overlay {
    padding-bottom: 15px;
  }
  .row > .post.gutterx-md,
  .row > .post.gutterx-md > .post-bg-overlay, .row >
  .post.gutterr-md,
  .row >
  .post.gutterr-md > .post-bg-overlay {
    padding-right: 15px;
  }
  .row > .post.gutterx-md,
  .row > .post.gutterx-md > .post-bg-overlay, .row >
  .post.gutterl-md,
  .row >
  .post.gutterl-md > .post-bg-overlay {
    padding-left: 15px;
  }
  .post.no-gutter-md {
    padding-top: 0;
    padding-bottom: 0;
  }
  .post.no-gutter-md > .post-bg-overlay {
    padding-top: 0;
    padding-bottom: 0;
  }
  .row > .post.no-gutter-md,
  .row > .post.no-gutter-md > .post-bg-overlay {
    padding-right: 0;
    padding-left: 0;
  }
  .post.no-guttert-md {
    padding-top: 0;
  }
  .post.no-guttert-md > .post-bg-overlay {
    padding-top: 0;
  }
  .post.no-gutterb-md {
    padding-bottom: 0;
  }
  .post.no-gutterb-md > .post-bg-overlay {
    padding-bottom: 0;
  }
  .row > .post.no-gutterx-md,
  .row > .post.no-gutterx-md > .post-bg-overlay, .row >
  .post.no-gutterr-md,
  .row >
  .post.no-gutterr-md > .post-bg-overlay {
    padding-right: 0;
  }
  .row > .post.no-gutterx-md,
  .row > .post.no-gutterx-md > .post-bg-overlay, .row >
  .post.no-gutterl-md,
  .row >
  .post.no-gutterl-md > .post-bg-overlay {
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .post.gutter-lg {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .post.gutter-lg > .post-bg-overlay {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .row > .post.gutter-lg,
  .row > .post.gutter-lg > .post-bg-overlay {
    padding-right: 15px;
    padding-left: 15px;
  }
  .post.guttert-lg {
    padding-top: 15px;
  }
  .post.guttert-lg > .post-bg-overlay {
    padding-top: 15px;
  }
  .post.gutterb-lg {
    padding-bottom: 15px;
  }
  .post.gutterb-lg > .post-bg-overlay {
    padding-bottom: 15px;
  }
  .row > .post.gutterx-lg,
  .row > .post.gutterx-lg > .post-bg-overlay, .row >
  .post.gutterr-lg,
  .row >
  .post.gutterr-lg > .post-bg-overlay {
    padding-right: 15px;
  }
  .row > .post.gutterx-lg,
  .row > .post.gutterx-lg > .post-bg-overlay, .row >
  .post.gutterl-lg,
  .row >
  .post.gutterl-lg > .post-bg-overlay {
    padding-left: 15px;
  }
  .post.no-gutter-lg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .post.no-gutter-lg > .post-bg-overlay {
    padding-top: 0;
    padding-bottom: 0;
  }
  .row > .post.no-gutter-lg,
  .row > .post.no-gutter-lg > .post-bg-overlay {
    padding-right: 0;
    padding-left: 0;
  }
  .post.no-guttert-lg {
    padding-top: 0;
  }
  .post.no-guttert-lg > .post-bg-overlay {
    padding-top: 0;
  }
  .post.no-gutterb-lg {
    padding-bottom: 0;
  }
  .post.no-gutterb-lg > .post-bg-overlay {
    padding-bottom: 0;
  }
  .row > .post.no-gutterx-lg,
  .row > .post.no-gutterx-lg > .post-bg-overlay, .row >
  .post.no-gutterr-lg,
  .row >
  .post.no-gutterr-lg > .post-bg-overlay {
    padding-right: 0;
  }
  .row > .post.no-gutterx-lg,
  .row > .post.no-gutterx-lg > .post-bg-overlay, .row >
  .post.no-gutterl-lg,
  .row >
  .post.no-gutterl-lg > .post-bg-overlay {
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .post.gutter-xl {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .post.gutter-xl > .post-bg-overlay {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .row > .post.gutter-xl,
  .row > .post.gutter-xl > .post-bg-overlay {
    padding-right: 15px;
    padding-left: 15px;
  }
  .post.guttert-xl {
    padding-top: 15px;
  }
  .post.guttert-xl > .post-bg-overlay {
    padding-top: 15px;
  }
  .post.gutterb-xl {
    padding-bottom: 15px;
  }
  .post.gutterb-xl > .post-bg-overlay {
    padding-bottom: 15px;
  }
  .row > .post.gutterx-xl,
  .row > .post.gutterx-xl > .post-bg-overlay, .row >
  .post.gutterr-xl,
  .row >
  .post.gutterr-xl > .post-bg-overlay {
    padding-right: 15px;
  }
  .row > .post.gutterx-xl,
  .row > .post.gutterx-xl > .post-bg-overlay, .row >
  .post.gutterl-xl,
  .row >
  .post.gutterl-xl > .post-bg-overlay {
    padding-left: 15px;
  }
  .post.no-gutter-xl {
    padding-top: 0;
    padding-bottom: 0;
  }
  .post.no-gutter-xl > .post-bg-overlay {
    padding-top: 0;
    padding-bottom: 0;
  }
  .row > .post.no-gutter-xl,
  .row > .post.no-gutter-xl > .post-bg-overlay {
    padding-right: 0;
    padding-left: 0;
  }
  .post.no-guttert-xl {
    padding-top: 0;
  }
  .post.no-guttert-xl > .post-bg-overlay {
    padding-top: 0;
  }
  .post.no-gutterb-xl {
    padding-bottom: 0;
  }
  .post.no-gutterb-xl > .post-bg-overlay {
    padding-bottom: 0;
  }
  .row > .post.no-gutterx-xl,
  .row > .post.no-gutterx-xl > .post-bg-overlay, .row >
  .post.no-gutterr-xl,
  .row >
  .post.no-gutterr-xl > .post-bg-overlay {
    padding-right: 0;
  }
  .row > .post.no-gutterx-xl,
  .row > .post.no-gutterx-xl > .post-bg-overlay, .row >
  .post.no-gutterl-xl,
  .row >
  .post.no-gutterl-xl > .post-bg-overlay {
    padding-left: 0;
  }
}

.post-thumbnail {
  position: relative;
}

.post-thumbnail img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.post-title {
  position: relative;
}

.post-title a:hover,
.post-overlay-link:hover ~ .post-title a,
.post-overlay-link:hover ~ div .post-title a {
  text-decoration: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.post-title > a:focus,
.post-overlay-link:focus ~ .post-title > a,
.post-overlay-link:focus ~ div .post-title > a {
  color: #fff !important;
  background-color: #4777fa !important;
}

.post-title:last-child {
  margin-bottom: 0;
}

.post-title > span {
  display: inline-block;
}

.post-meta {
  font-size: 0.97rem;
}

.post-summary .post-meta {
  font-size: 0.8rem;
}

.post-meta p {
  margin-bottom: 0.7rem;
}

.post-meta a {
  position: relative;
  z-index: 5;
}

.post-excerpt {
  position: relative;
}

.post-excerpt p:not(.lead) {
  margin-bottom: .75em;
}

@media (min-width: 768px) {
  .post-readerbar > p,
  .post-readerbar > figure {
    display: block;
    max-width: 680px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .post-readerbarr > p,
  .post-readerbarr > figure {
    display: block;
    max-width: 680px;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .post-readerbarl > p,
  .post-readerbarl > figure {
    display: block;
    max-width: 680px;
    margin-right: auto;
  }
}

.post-content {
  position: relative;
}

.post-content .figure {
  margin-top: .5rem;
}

.post-content .section {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.post-content a:hover {
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  .post-content > .alignright,
  .post-content > .alignleft,
  .post-content > .float-right,
  .post-content > .float-left {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .post-content > .w-window {
    max-width: initial !important;
  }
  .post-content > .alignright {
    float: right !important;
  }
  .post-content > .alignleft {
    float: left !important;
  }
  .post-content > .alignright,
  .post-content > .float-right {
    float: right !important;
    margin-left: 1rem;
  }
  .post-content > .alignleft,
  .post-content > .float-left {
    float: left !important;
    margin-right: 1rem;
  }
  .post-content blockquote {
    max-width: calc(253px - 30px) !important;
  }
  .post-content .size-sm {
    max-width: calc(45% - 15px) !important;
  }
  .post-content .size-md {
    max-width: calc(60% - 15px) !important;
  }
  .post-content .size-lg {
    max-width: 680px !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .post-content .size-xl {
    max-width: 100% !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .post-content .size-sm {
    max-width: calc(33% - 16px) !important;
  }
  .post-content .size-md {
    max-width: calc(50% - 16px) !important;
  }
}

.post-light .post-title {
  color: #1b1f28;
}

.post-light .post-title a {
  color: #1b1f28;
  border-color: rgba(71, 119, 250, 0.2);
}

.post-light .post-title a:hover,
.post-light .post-overlay-link:hover ~ .post-title a,
.post-light .post-overlay-link:hover ~ div .post-title a {
  color: #4777fa !important;
}

.post-light .post-meta {
  color: #8a92a5;
}

.post-light .post-meta a {
  color: #8a92a5;
}

.post-light .post-meta a:hover {
  color: #4777fa;
}

.post-light .post-excerpt {
  position: relative;
  color: #8a92a5;
}

.post-light .post-content .blockquote, .post-light.post-content .blockquote {
  color: #1b1f28;
}

.post-dark {
  color: #f7f7f7;
}

.post-dark a {
  color: #f7f7f7;
}

.post-dark a:hover {
  color: #4777fa;
}

.post-dark .post-title {
  color: #f2f2f2;
}

.post-dark .post-title a {
  color: #f2f2f2;
  border-color: rgba(255, 255, 255, 0.2);
}

.post-dark .post-title a:hover {
  color: #4777fa;
}

.post-dark .post-title a:hover,
.post-dark .post-overlay-link:hover ~ .post-title a,
.post-dark .post-overlay-link:hover ~ div .post-title a {
  color: #4777fa;
}

.post-dark .post-meta {
  color: #ced4da;
}

.post-dark .post-meta a {
  color: #ced4da;
}

.post-dark .post-meta a:hover {
  color: #4777fa;
}

.post-dark .post-excerpt {
  color: #ced4da;
}

.post-dark .post-content .blockquote, .post-dark.post-content .blockquote {
  color: #fff;
}

.rank {
  display: block;
  width: 32px;
  height: 32px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}

.rank.overlay-c-c {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
}

.rank.overlay-t-r {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

.icon-xs {
  font-size: 1.5rem;
}

.icon-sm {
  font-size: 2rem;
}

.icon-md {
  font-size: 2.5rem;
}

.icon-lg {
  font-size: 3rem;
}

.icon-xl {
  font-size: 4rem;
}

.icon-full {
  font-size: 5rem;
}

hr.divider,
hr.divider-top,
hr.divider-bottom {
  position: relative;
}

.divider-top {
  border-top: 1px solid;
}

.divider-bottom {
  border-bottom: 1px solid;
}

.post-light .divider,
.post-light.divider,
.navbar-light .divider,
.navbar-light.divider,
.divider.divider-light {
  border-color: rgba(52, 58, 64, 0.1) !important;
}

.post-dark .divider,
.post-dark.divider,
.navbar-dark .divider,
.navbar-dark.divider,
.divider.divider-dark {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.banner {
  position: relative;
  background-color: #dee2e6;
}

.banner-468x60 {
  width: 468px;
  height: 60px;
}

.banner-728x90 {
  width: 728px;
  height: 90px;
}

.banner-300x250 {
  width: 300px;
  height: 250px;
}

.banner-120x600 {
  width: 120px;
  height: 600px;
}

.banner-160x600 {
  width: 160px;
  height: 600px;
}

.banner-300x600 {
  width: 300px;
  height: 600px;
}

.banner-234x60 {
  width: 234px;
  height: 600px;
}

.banner-88x31 {
  width: 88px;
  height: 31px;
}

.banner-120x60 {
  width: 120px;
  height: 60px;
}

.banner-120x90 {
  width: 120px;
  height: 90px;
}

.banner-120x240 {
  width: 120px;
  height: 240px;
}

.bg-primary {
  background-color: #4777fa !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1552f9 !important;
}

.bg-secondary {
  background-color: #8a92a5 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #6e778e !important;
}

.bg-dark {
  background-color: #1b1f28 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #06070a !important;
}

.bg-gray-100 {
  background-color: #f8f9fa !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #dae0e5 !important;
}

.bg-gray-200 {
  background-color: #e9ecef !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cbd3da !important;
}

.bg-gray-300 {
  background-color: #dee2e6 !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c1c9d0 !important;
}

.bg-gray-400 {
  background-color: #ced4da !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b1bbc4 !important;
}

.bg-gray-500 {
  background-color: #adb5bd !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #919ca6 !important;
}

.bg-gray-600 {
  background-color: #6c757d !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #545b62 !important;
}

.bg-gray-700 {
  background-color: #495057 !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #32373b !important;
}

.bg-gray-800 {
  background-color: #343a40 !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #1d2124 !important;
}

.bg-gray-900 {
  background-color: #212529 !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #0a0c0d !important;
}

.bg-gradient-b-100 {
  background-image: linear-gradient(to bottom, rgba(248, 249, 250, 0) 0%, #f8f9fa 100%);
  background-repeat: repeat-x;
}

.bg-gradient-b-200 {
  background-image: linear-gradient(to bottom, rgba(233, 236, 239, 0) 0%, #e9ecef 100%);
  background-repeat: repeat-x;
}

.bg-gradient-b-300 {
  background-image: linear-gradient(to bottom, rgba(222, 226, 230, 0) 0%, #dee2e6 100%);
  background-repeat: repeat-x;
}

.bg-gradient-b-400 {
  background-image: linear-gradient(to bottom, rgba(206, 212, 218, 0) 0%, #ced4da 100%);
  background-repeat: repeat-x;
}

.bg-gradient-b-500 {
  background-image: linear-gradient(to bottom, rgba(173, 181, 189, 0) 0%, #adb5bd 100%);
  background-repeat: repeat-x;
}

.bg-gradient-b-600 {
  background-image: linear-gradient(to bottom, rgba(108, 117, 125, 0) 0%, #6c757d 100%);
  background-repeat: repeat-x;
}

.bg-gradient-b-700 {
  background-image: linear-gradient(to bottom, rgba(73, 80, 87, 0) 0%, #495057 100%);
  background-repeat: repeat-x;
}

.bg-gradient-b-800 {
  background-image: linear-gradient(to bottom, rgba(52, 58, 64, 0) 0%, #343a40 100%);
  background-repeat: repeat-x;
}

.bg-gradient-b-900 {
  background-image: linear-gradient(to bottom, rgba(33, 37, 41, 0) 0%, #212529 100%);
  background-repeat: repeat-x;
}

.bg-black {
  background-color: #000 !important;
}

.border-primary {
  border-color: #4777fa !important;
}

.border-secondary {
  border-color: #8a92a5 !important;
}

.border-dark {
  border-color: #1b1f28 !important;
}

.border-gray-100 {
  border-color: #f8f9fa !important;
}

.border-gray-200 {
  border-color: #e9ecef !important;
}

.border-gray-300 {
  border-color: #dee2e6 !important;
}

.border-gray-400 {
  border-color: #ced4da !important;
}

.border-gray-500 {
  border-color: #adb5bd !important;
}

.border-gray-600 {
  border-color: #6c757d !important;
}

.border-gray-700 {
  border-color: #495057 !important;
}

.border-gray-800 {
  border-color: #343a40 !important;
}

.border-gray-900 {
  border-color: #212529 !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.t-gutter,
.y-gutter {
  top: 15px !important;
}

.r-gutter,
.x-gutter {
  right: 15px !important;
}

.b-gutter,
.y-gutter {
  bottom: 15px !important;
}

.l-gutter,
.X-gutter {
  left: 15px !important;
}

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .t-gutter,
  .y-gutter {
    top: 15px !important;
  }
  .r-gutter,
  .x-gutter {
    right: 15px !important;
  }
  .b-gutter,
  .y-gutter {
    bottom: 15px !important;
  }
  .l-gutter,
  .X-gutter {
    left: 15px !important;
  }
}

@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .t-gutter,
  .y-gutter {
    top: 15px !important;
  }
  .r-gutter,
  .x-gutter {
    right: 15px !important;
  }
  .b-gutter,
  .y-gutter {
    bottom: 15px !important;
  }
  .l-gutter,
  .X-gutter {
    left: 15px !important;
  }
}

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .t-gutter,
  .y-gutter {
    top: 15px !important;
  }
  .r-gutter,
  .x-gutter {
    right: 15px !important;
  }
  .b-gutter,
  .y-gutter {
    bottom: 15px !important;
  }
  .l-gutter,
  .X-gutter {
    left: 15px !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .t-gutter,
  .y-gutter {
    top: 15px !important;
  }
  .r-gutter,
  .x-gutter {
    right: 15px !important;
  }
  .b-gutter,
  .y-gutter {
    bottom: 15px !important;
  }
  .l-gutter,
  .X-gutter {
    left: 15px !important;
  }
}

.sticky-col {
  z-index: 4;
}

.w-25 {
  width: 25% !important;
}

.miw-25 {
  min-width: 25% !important;
}

.mw-25 {
  max-width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.miw-50 {
  min-width: 50% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.miw-75 {
  min-width: 75% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.miw-100 {
  min-width: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.miw-auto {
  min-width: auto !important;
}

.mw-auto {
  max-width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.mih-25 {
  min-height: 25% !important;
}

.mh-25 {
  max-height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.mih-50 {
  min-height: 50% !important;
}

.mh-50 {
  max-height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.mih-75 {
  min-height: 75% !important;
}

.mh-75 {
  max-height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mih-100 {
  min-height: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mih-auto {
  min-height: auto !important;
}

.mh-auto {
  max-height: auto !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-100vh.bg-overlapped, .h-100vh.bg-overlappedb {
  height: calc(100vh - 210px) !important;
}

.h-100vh.bg-overlapped-sm, .h-100vh.bg-overlappedb-sm {
  height: calc(100vh - 52.5px) !important;
}

.h-50vh {
  height: 50vh !important;
}

.h-50vh.bg-overlapped, .h-50vh.bg-overlappedb {
  height: calc(50vh - 210px) !important;
}

.h-50vh.bg-overlapped-sm, .h-50vh.bg-overlappedb-sm {
  height: calc(50vh - 52.5px) !important;
}

.h-x1 {
  height: 210px !important;
}

.h-x2 {
  height: 420px !important;
}

.h-x3 {
  height: 630px !important;
}

.mih-x1 {
  min-height: 210px !important;
}

.mih-x2 {
  min-height: 420px !important;
}

.mih-x3 {
  min-height: 630px !important;
}

.mih-100vh {
  min-height: 100vh;
}

.h-auto {
  height: auto !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .miw-sm-25 {
    min-width: 25% !important;
  }
  .mw-sm-25 {
    max-width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .miw-sm-50 {
    min-width: 50% !important;
  }
  .mw-sm-50 {
    max-width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .miw-sm-75 {
    min-width: 75% !important;
  }
  .mw-sm-75 {
    max-width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .miw-sm-100 {
    min-width: 100% !important;
  }
  .mw-sm-100 {
    max-width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .miw-sm-auto {
    min-width: auto !important;
  }
  .mw-sm-auto {
    max-width: auto !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .mih-sm-25 {
    min-height: 25% !important;
  }
  .mh-sm-25 {
    max-height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .mih-sm-50 {
    min-height: 50% !important;
  }
  .mh-sm-50 {
    max-height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .mih-sm-75 {
    min-height: 75% !important;
  }
  .mh-sm-75 {
    max-height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .mih-sm-100 {
    min-height: 100% !important;
  }
  .mh-sm-100 {
    max-height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .mih-sm-auto {
    min-height: auto !important;
  }
  .mh-sm-auto {
    max-height: auto !important;
  }
  .h-sm-100vh {
    height: 100vh !important;
  }
  .h-sm-100vh.bg-overlapped, .h-sm-100vh.bg-overlappedb {
    height: calc(100vh - 210px) !important;
  }
  .h-sm-100vh.bg-overlapped-sm, .h-sm-100vh.bg-overlappedb-sm {
    height: calc(100vh - 52.5px) !important;
  }
  .h-sm-50vh {
    height: 50vh !important;
  }
  .h-sm-50vh.bg-overlapped, .h-sm-50vh.bg-overlappedb {
    height: calc(50vh - 210px) !important;
  }
  .h-sm-50vh.bg-overlapped-sm, .h-sm-50vh.bg-overlappedb-sm {
    height: calc(50vh - 52.5px) !important;
  }
  .h-sm-x1 {
    height: 210px !important;
  }
  .h-sm-x2 {
    height: 420px !important;
  }
  .h-sm-x3 {
    height: 630px !important;
  }
  .mih-sm-x1 {
    min-height: 210px !important;
  }
  .mih-sm-x2 {
    min-height: 420px !important;
  }
  .mih-sm-x3 {
    min-height: 630px !important;
  }
  .mih-sm-100vh {
    min-height: 100vh;
  }
  .h-sm-auto {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .miw-md-25 {
    min-width: 25% !important;
  }
  .mw-md-25 {
    max-width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .miw-md-50 {
    min-width: 50% !important;
  }
  .mw-md-50 {
    max-width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .miw-md-75 {
    min-width: 75% !important;
  }
  .mw-md-75 {
    max-width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .miw-md-100 {
    min-width: 100% !important;
  }
  .mw-md-100 {
    max-width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .miw-md-auto {
    min-width: auto !important;
  }
  .mw-md-auto {
    max-width: auto !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .mih-md-25 {
    min-height: 25% !important;
  }
  .mh-md-25 {
    max-height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .mih-md-50 {
    min-height: 50% !important;
  }
  .mh-md-50 {
    max-height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .mih-md-75 {
    min-height: 75% !important;
  }
  .mh-md-75 {
    max-height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .mih-md-100 {
    min-height: 100% !important;
  }
  .mh-md-100 {
    max-height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .mih-md-auto {
    min-height: auto !important;
  }
  .mh-md-auto {
    max-height: auto !important;
  }
  .h-md-100vh {
    height: 100vh !important;
  }
  .h-md-100vh.bg-overlapped, .h-md-100vh.bg-overlappedb {
    height: calc(100vh - 210px) !important;
  }
  .h-md-100vh.bg-overlapped-sm, .h-md-100vh.bg-overlappedb-sm {
    height: calc(100vh - 52.5px) !important;
  }
  .h-md-50vh {
    height: 50vh !important;
  }
  .h-md-50vh.bg-overlapped, .h-md-50vh.bg-overlappedb {
    height: calc(50vh - 210px) !important;
  }
  .h-md-50vh.bg-overlapped-sm, .h-md-50vh.bg-overlappedb-sm {
    height: calc(50vh - 52.5px) !important;
  }
  .h-md-x1 {
    height: 210px !important;
  }
  .h-md-x2 {
    height: 420px !important;
  }
  .h-md-x3 {
    height: 630px !important;
  }
  .mih-md-x1 {
    min-height: 210px !important;
  }
  .mih-md-x2 {
    min-height: 420px !important;
  }
  .mih-md-x3 {
    min-height: 630px !important;
  }
  .mih-md-100vh {
    min-height: 100vh;
  }
  .h-md-auto {
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .miw-lg-25 {
    min-width: 25% !important;
  }
  .mw-lg-25 {
    max-width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .miw-lg-50 {
    min-width: 50% !important;
  }
  .mw-lg-50 {
    max-width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .miw-lg-75 {
    min-width: 75% !important;
  }
  .mw-lg-75 {
    max-width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .miw-lg-100 {
    min-width: 100% !important;
  }
  .mw-lg-100 {
    max-width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .miw-lg-auto {
    min-width: auto !important;
  }
  .mw-lg-auto {
    max-width: auto !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .mih-lg-25 {
    min-height: 25% !important;
  }
  .mh-lg-25 {
    max-height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .mih-lg-50 {
    min-height: 50% !important;
  }
  .mh-lg-50 {
    max-height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .mih-lg-75 {
    min-height: 75% !important;
  }
  .mh-lg-75 {
    max-height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .mih-lg-100 {
    min-height: 100% !important;
  }
  .mh-lg-100 {
    max-height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .mih-lg-auto {
    min-height: auto !important;
  }
  .mh-lg-auto {
    max-height: auto !important;
  }
  .h-lg-100vh {
    height: 100vh !important;
  }
  .h-lg-100vh.bg-overlapped, .h-lg-100vh.bg-overlappedb {
    height: calc(100vh - 210px) !important;
  }
  .h-lg-100vh.bg-overlapped-sm, .h-lg-100vh.bg-overlappedb-sm {
    height: calc(100vh - 52.5px) !important;
  }
  .h-lg-50vh {
    height: 50vh !important;
  }
  .h-lg-50vh.bg-overlapped, .h-lg-50vh.bg-overlappedb {
    height: calc(50vh - 210px) !important;
  }
  .h-lg-50vh.bg-overlapped-sm, .h-lg-50vh.bg-overlappedb-sm {
    height: calc(50vh - 52.5px) !important;
  }
  .h-lg-x1 {
    height: 210px !important;
  }
  .h-lg-x2 {
    height: 420px !important;
  }
  .h-lg-x3 {
    height: 630px !important;
  }
  .mih-lg-x1 {
    min-height: 210px !important;
  }
  .mih-lg-x2 {
    min-height: 420px !important;
  }
  .mih-lg-x3 {
    min-height: 630px !important;
  }
  .mih-lg-100vh {
    min-height: 100vh;
  }
  .h-lg-auto {
    height: auto !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .miw-xl-25 {
    min-width: 25% !important;
  }
  .mw-xl-25 {
    max-width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .miw-xl-50 {
    min-width: 50% !important;
  }
  .mw-xl-50 {
    max-width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .miw-xl-75 {
    min-width: 75% !important;
  }
  .mw-xl-75 {
    max-width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .miw-xl-100 {
    min-width: 100% !important;
  }
  .mw-xl-100 {
    max-width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .miw-xl-auto {
    min-width: auto !important;
  }
  .mw-xl-auto {
    max-width: auto !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .mih-xl-25 {
    min-height: 25% !important;
  }
  .mh-xl-25 {
    max-height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .mih-xl-50 {
    min-height: 50% !important;
  }
  .mh-xl-50 {
    max-height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .mih-xl-75 {
    min-height: 75% !important;
  }
  .mh-xl-75 {
    max-height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .mih-xl-100 {
    min-height: 100% !important;
  }
  .mh-xl-100 {
    max-height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .mih-xl-auto {
    min-height: auto !important;
  }
  .mh-xl-auto {
    max-height: auto !important;
  }
  .h-xl-100vh {
    height: 100vh !important;
  }
  .h-xl-100vh.bg-overlapped, .h-xl-100vh.bg-overlappedb {
    height: calc(100vh - 210px) !important;
  }
  .h-xl-100vh.bg-overlapped-sm, .h-xl-100vh.bg-overlappedb-sm {
    height: calc(100vh - 52.5px) !important;
  }
  .h-xl-50vh {
    height: 50vh !important;
  }
  .h-xl-50vh.bg-overlapped, .h-xl-50vh.bg-overlappedb {
    height: calc(50vh - 210px) !important;
  }
  .h-xl-50vh.bg-overlapped-sm, .h-xl-50vh.bg-overlappedb-sm {
    height: calc(50vh - 52.5px) !important;
  }
  .h-xl-x1 {
    height: 210px !important;
  }
  .h-xl-x2 {
    height: 420px !important;
  }
  .h-xl-x3 {
    height: 630px !important;
  }
  .mih-xl-x1 {
    min-height: 210px !important;
  }
  .mih-xl-x2 {
    min-height: 420px !important;
  }
  .mih-xl-x3 {
    min-height: 630px !important;
  }
  .mih-xl-100vh {
    min-height: 100vh;
  }
  .h-xl-auto {
    height: auto !important;
  }
}

.w-window {
  position: relative;
  right: 50% !important;
  left: 50% !important;
  width: 100vw !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}

[class^="col"] .w-window,
[class*=" col"] .w-window {
  width: 120vw !important;
}

.col-12 .w-window {
  width: 100vw !important;
}

@media (min-width: 576px) {
  [class^="col-sm"] .w-window,
  [class*=" col-sm"] .w-window {
    width: 120vw !important;
  }
  .col-sm-12 .w-window {
    width: 100vw !important;
  }
}

@media (min-width: 768px) {
  [class^="col-md"] .w-window,
  [class*=" col-md"] .w-window {
    width: 120vw !important;
  }
  .col-md-12 .w-window {
    width: 100vw !important;
  }
}

@media (min-width: 992px) {
  [class^="col-lg"] .w-window,
  [class*=" col-lg"] .w-window {
    width: 120vw !important;
  }
  .col-lg-12 .w-window {
    width: 100vw !important;
  }
}

@media (min-width: 1200px) {
  [class^="col-xl"] .w-window,
  [class*=" col-xl"] .w-window {
    width: 120vw !important;
  }
  .col-xl-12 .w-window {
    width: 100vw !important;
  }
}

.w-readerbar {
  width: 100%;
  max-width: 680px;
}

.m-gutter-2 {
  margin: 30px !important;
}

.mt-gutter-2,
.my-gutter-2 {
  margin-top: 30px !important;
}

.mr-gutter-2,
.mx-gutter-2 {
  margin-right: 30px !important;
}

.mb-gutter-2,
.my-gutter-2 {
  margin-bottom: 30px !important;
}

.ml-gutter-2,
.mx-gutter-2 {
  margin-left: 30px !important;
}

.m-gutter {
  margin: 15px !important;
}

.mt-gutter,
.my-gutter {
  margin-top: 15px !important;
}

.mr-gutter,
.mx-gutter {
  margin-right: 15px !important;
}

.mb-gutter,
.my-gutter {
  margin-bottom: 15px !important;
}

.ml-gutter,
.mx-gutter {
  margin-left: 15px !important;
}

.p-gutter-2 {
  padding: 30px !important;
}

.pt-gutter-2,
.py-gutter-2 {
  padding-top: 30px !important;
}

.pr-gutter-2,
.px-gutter-2 {
  padding-right: 30px !important;
}

.pb-gutter-2,
.py-gutter-2 {
  padding-bottom: 30px !important;
}

.pl-gutter-2,
.px-gutter-2 {
  padding-left: 30px !important;
}

.p-gutter {
  padding: 15px !important;
}

.pt-gutter,
.py-gutter {
  padding-top: 15px !important;
}

.pr-gutter,
.px-gutter {
  padding-right: 15px !important;
}

.pb-gutter,
.py-gutter {
  padding-bottom: 15px !important;
}

.pl-gutter,
.px-gutter {
  padding-left: 15px !important;
}

.m--gutter-2 {
  margin: -30px !important;
}

.mt--gutter-2,
.my--gutter-2 {
  margin-top: -30px !important;
}

.mr--gutter-2,
.mx--gutter-2 {
  margin-right: -30px !important;
}

.mb--gutter-2,
.my--gutter-2 {
  margin-bottom: -30px !important;
}

.ml--gutter-2,
.mx--gutter-2 {
  margin-left: -30px !important;
}

.m--gutter {
  margin: -15px !important;
}

.mt--gutter,
.my--gutter {
  margin-top: -15px !important;
}

.mr--gutter,
.mx--gutter {
  margin-right: -15px !important;
}

.mb--gutter,
.my--gutter {
  margin-bottom: -15px !important;
}

.ml--gutter,
.mx--gutter {
  margin-left: -15px !important;
}

.mr-sbar {
  margin-right: 330px !important;
}

.ml-sbar {
  margin-left: 330px !important;
}

.mr-sbar-sm {
  margin-right: 190px !important;
}

.ml-sbar-sm {
  margin-left: 190px !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

@media (min-width: 576px) {
  .m-sm-gutter-2 {
    margin: 30px !important;
  }
  .mt-sm-gutter-2,
  .my-sm-gutter-2 {
    margin-top: 30px !important;
  }
  .mr-sm-gutter-2,
  .mx-sm-gutter-2 {
    margin-right: 30px !important;
  }
  .mb-sm-gutter-2,
  .my-sm-gutter-2 {
    margin-bottom: 30px !important;
  }
  .ml-sm-gutter-2,
  .mx-sm-gutter-2 {
    margin-left: 30px !important;
  }
  .m-sm-gutter {
    margin: 15px !important;
  }
  .mt-sm-gutter,
  .my-sm-gutter {
    margin-top: 15px !important;
  }
  .mr-sm-gutter,
  .mx-sm-gutter {
    margin-right: 15px !important;
  }
  .mb-sm-gutter,
  .my-sm-gutter {
    margin-bottom: 15px !important;
  }
  .ml-sm-gutter,
  .mx-sm-gutter {
    margin-left: 15px !important;
  }
  .p-sm-gutter-2 {
    padding: 30px !important;
  }
  .pt-sm-gutter-2,
  .py-sm-gutter-2 {
    padding-top: 30px !important;
  }
  .pr-sm-gutter-2,
  .px-sm-gutter-2 {
    padding-right: 30px !important;
  }
  .pb-sm-gutter-2,
  .py-sm-gutter-2 {
    padding-bottom: 30px !important;
  }
  .pl-sm-gutter-2,
  .px-sm-gutter-2 {
    padding-left: 30px !important;
  }
  .p-sm-gutter {
    padding: 15px !important;
  }
  .pt-sm-gutter,
  .py-sm-gutter {
    padding-top: 15px !important;
  }
  .pr-sm-gutter,
  .px-sm-gutter {
    padding-right: 15px !important;
  }
  .pb-sm-gutter,
  .py-sm-gutter {
    padding-bottom: 15px !important;
  }
  .pl-sm-gutter,
  .px-sm-gutter {
    padding-left: 15px !important;
  }
  .m-sm--gutter-2 {
    margin: -30px !important;
  }
  .mt-sm--gutter-2,
  .my-sm--gutter-2 {
    margin-top: -30px !important;
  }
  .mr-sm--gutter-2,
  .mx-sm--gutter-2 {
    margin-right: -30px !important;
  }
  .mb-sm--gutter-2,
  .my-sm--gutter-2 {
    margin-bottom: -30px !important;
  }
  .ml-sm--gutter-2,
  .mx-sm--gutter-2 {
    margin-left: -30px !important;
  }
  .m-sm--gutter {
    margin: -15px !important;
  }
  .mt-sm--gutter,
  .my-sm--gutter {
    margin-top: -15px !important;
  }
  .mr-sm--gutter,
  .mx-sm--gutter {
    margin-right: -15px !important;
  }
  .mb-sm--gutter,
  .my-sm--gutter {
    margin-bottom: -15px !important;
  }
  .ml-sm--gutter,
  .mx-sm--gutter {
    margin-left: -15px !important;
  }
  .mr-sm-sbar {
    margin-right: 330px !important;
  }
  .ml-sm-sbar {
    margin-left: 330px !important;
  }
  .mr-sm-sbar-sm {
    margin-right: 190px !important;
  }
  .ml-sm-sbar-sm {
    margin-left: 190px !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .m-md-gutter-2 {
    margin: 30px !important;
  }
  .mt-md-gutter-2,
  .my-md-gutter-2 {
    margin-top: 30px !important;
  }
  .mr-md-gutter-2,
  .mx-md-gutter-2 {
    margin-right: 30px !important;
  }
  .mb-md-gutter-2,
  .my-md-gutter-2 {
    margin-bottom: 30px !important;
  }
  .ml-md-gutter-2,
  .mx-md-gutter-2 {
    margin-left: 30px !important;
  }
  .m-md-gutter {
    margin: 15px !important;
  }
  .mt-md-gutter,
  .my-md-gutter {
    margin-top: 15px !important;
  }
  .mr-md-gutter,
  .mx-md-gutter {
    margin-right: 15px !important;
  }
  .mb-md-gutter,
  .my-md-gutter {
    margin-bottom: 15px !important;
  }
  .ml-md-gutter,
  .mx-md-gutter {
    margin-left: 15px !important;
  }
  .p-md-gutter-2 {
    padding: 30px !important;
  }
  .pt-md-gutter-2,
  .py-md-gutter-2 {
    padding-top: 30px !important;
  }
  .pr-md-gutter-2,
  .px-md-gutter-2 {
    padding-right: 30px !important;
  }
  .pb-md-gutter-2,
  .py-md-gutter-2 {
    padding-bottom: 30px !important;
  }
  .pl-md-gutter-2,
  .px-md-gutter-2 {
    padding-left: 30px !important;
  }
  .p-md-gutter {
    padding: 15px !important;
  }
  .pt-md-gutter,
  .py-md-gutter {
    padding-top: 15px !important;
  }
  .pr-md-gutter,
  .px-md-gutter {
    padding-right: 15px !important;
  }
  .pb-md-gutter,
  .py-md-gutter {
    padding-bottom: 15px !important;
  }
  .pl-md-gutter,
  .px-md-gutter {
    padding-left: 15px !important;
  }
  .m-md--gutter-2 {
    margin: -30px !important;
  }
  .mt-md--gutter-2,
  .my-md--gutter-2 {
    margin-top: -30px !important;
  }
  .mr-md--gutter-2,
  .mx-md--gutter-2 {
    margin-right: -30px !important;
  }
  .mb-md--gutter-2,
  .my-md--gutter-2 {
    margin-bottom: -30px !important;
  }
  .ml-md--gutter-2,
  .mx-md--gutter-2 {
    margin-left: -30px !important;
  }
  .m-md--gutter {
    margin: -15px !important;
  }
  .mt-md--gutter,
  .my-md--gutter {
    margin-top: -15px !important;
  }
  .mr-md--gutter,
  .mx-md--gutter {
    margin-right: -15px !important;
  }
  .mb-md--gutter,
  .my-md--gutter {
    margin-bottom: -15px !important;
  }
  .ml-md--gutter,
  .mx-md--gutter {
    margin-left: -15px !important;
  }
  .mr-md-sbar {
    margin-right: 330px !important;
  }
  .ml-md-sbar {
    margin-left: 330px !important;
  }
  .mr-md-sbar-sm {
    margin-right: 190px !important;
  }
  .ml-md-sbar-sm {
    margin-left: 190px !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .m-lg-gutter-2 {
    margin: 30px !important;
  }
  .mt-lg-gutter-2,
  .my-lg-gutter-2 {
    margin-top: 30px !important;
  }
  .mr-lg-gutter-2,
  .mx-lg-gutter-2 {
    margin-right: 30px !important;
  }
  .mb-lg-gutter-2,
  .my-lg-gutter-2 {
    margin-bottom: 30px !important;
  }
  .ml-lg-gutter-2,
  .mx-lg-gutter-2 {
    margin-left: 30px !important;
  }
  .m-lg-gutter {
    margin: 15px !important;
  }
  .mt-lg-gutter,
  .my-lg-gutter {
    margin-top: 15px !important;
  }
  .mr-lg-gutter,
  .mx-lg-gutter {
    margin-right: 15px !important;
  }
  .mb-lg-gutter,
  .my-lg-gutter {
    margin-bottom: 15px !important;
  }
  .ml-lg-gutter,
  .mx-lg-gutter {
    margin-left: 15px !important;
  }
  .p-lg-gutter-2 {
    padding: 30px !important;
  }
  .pt-lg-gutter-2,
  .py-lg-gutter-2 {
    padding-top: 30px !important;
  }
  .pr-lg-gutter-2,
  .px-lg-gutter-2 {
    padding-right: 30px !important;
  }
  .pb-lg-gutter-2,
  .py-lg-gutter-2 {
    padding-bottom: 30px !important;
  }
  .pl-lg-gutter-2,
  .px-lg-gutter-2 {
    padding-left: 30px !important;
  }
  .p-lg-gutter {
    padding: 15px !important;
  }
  .pt-lg-gutter,
  .py-lg-gutter {
    padding-top: 15px !important;
  }
  .pr-lg-gutter,
  .px-lg-gutter {
    padding-right: 15px !important;
  }
  .pb-lg-gutter,
  .py-lg-gutter {
    padding-bottom: 15px !important;
  }
  .pl-lg-gutter,
  .px-lg-gutter {
    padding-left: 15px !important;
  }
  .m-lg--gutter-2 {
    margin: -30px !important;
  }
  .mt-lg--gutter-2,
  .my-lg--gutter-2 {
    margin-top: -30px !important;
  }
  .mr-lg--gutter-2,
  .mx-lg--gutter-2 {
    margin-right: -30px !important;
  }
  .mb-lg--gutter-2,
  .my-lg--gutter-2 {
    margin-bottom: -30px !important;
  }
  .ml-lg--gutter-2,
  .mx-lg--gutter-2 {
    margin-left: -30px !important;
  }
  .m-lg--gutter {
    margin: -15px !important;
  }
  .mt-lg--gutter,
  .my-lg--gutter {
    margin-top: -15px !important;
  }
  .mr-lg--gutter,
  .mx-lg--gutter {
    margin-right: -15px !important;
  }
  .mb-lg--gutter,
  .my-lg--gutter {
    margin-bottom: -15px !important;
  }
  .ml-lg--gutter,
  .mx-lg--gutter {
    margin-left: -15px !important;
  }
  .mr-lg-sbar {
    margin-right: 330px !important;
  }
  .ml-lg-sbar {
    margin-left: 330px !important;
  }
  .mr-lg-sbar-sm {
    margin-right: 190px !important;
  }
  .ml-lg-sbar-sm {
    margin-left: 190px !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-gutter-2 {
    margin: 30px !important;
  }
  .mt-xl-gutter-2,
  .my-xl-gutter-2 {
    margin-top: 30px !important;
  }
  .mr-xl-gutter-2,
  .mx-xl-gutter-2 {
    margin-right: 30px !important;
  }
  .mb-xl-gutter-2,
  .my-xl-gutter-2 {
    margin-bottom: 30px !important;
  }
  .ml-xl-gutter-2,
  .mx-xl-gutter-2 {
    margin-left: 30px !important;
  }
  .m-xl-gutter {
    margin: 15px !important;
  }
  .mt-xl-gutter,
  .my-xl-gutter {
    margin-top: 15px !important;
  }
  .mr-xl-gutter,
  .mx-xl-gutter {
    margin-right: 15px !important;
  }
  .mb-xl-gutter,
  .my-xl-gutter {
    margin-bottom: 15px !important;
  }
  .ml-xl-gutter,
  .mx-xl-gutter {
    margin-left: 15px !important;
  }
  .p-xl-gutter-2 {
    padding: 30px !important;
  }
  .pt-xl-gutter-2,
  .py-xl-gutter-2 {
    padding-top: 30px !important;
  }
  .pr-xl-gutter-2,
  .px-xl-gutter-2 {
    padding-right: 30px !important;
  }
  .pb-xl-gutter-2,
  .py-xl-gutter-2 {
    padding-bottom: 30px !important;
  }
  .pl-xl-gutter-2,
  .px-xl-gutter-2 {
    padding-left: 30px !important;
  }
  .p-xl-gutter {
    padding: 15px !important;
  }
  .pt-xl-gutter,
  .py-xl-gutter {
    padding-top: 15px !important;
  }
  .pr-xl-gutter,
  .px-xl-gutter {
    padding-right: 15px !important;
  }
  .pb-xl-gutter,
  .py-xl-gutter {
    padding-bottom: 15px !important;
  }
  .pl-xl-gutter,
  .px-xl-gutter {
    padding-left: 15px !important;
  }
  .m-xl--gutter-2 {
    margin: -30px !important;
  }
  .mt-xl--gutter-2,
  .my-xl--gutter-2 {
    margin-top: -30px !important;
  }
  .mr-xl--gutter-2,
  .mx-xl--gutter-2 {
    margin-right: -30px !important;
  }
  .mb-xl--gutter-2,
  .my-xl--gutter-2 {
    margin-bottom: -30px !important;
  }
  .ml-xl--gutter-2,
  .mx-xl--gutter-2 {
    margin-left: -30px !important;
  }
  .m-xl--gutter {
    margin: -15px !important;
  }
  .mt-xl--gutter,
  .my-xl--gutter {
    margin-top: -15px !important;
  }
  .mr-xl--gutter,
  .mx-xl--gutter {
    margin-right: -15px !important;
  }
  .mb-xl--gutter,
  .my-xl--gutter {
    margin-bottom: -15px !important;
  }
  .ml-xl--gutter,
  .mx-xl--gutter {
    margin-left: -15px !important;
  }
  .mr-xl-sbar {
    margin-right: 330px !important;
  }
  .ml-xl-sbar {
    margin-left: 330px !important;
  }
  .mr-xl-sbar-sm {
    margin-right: 190px !important;
  }
  .ml-xl-sbar-sm {
    margin-left: 190px !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
}

.border-primary {
  border-color: #4777fa !important;
}

.border-secondary {
  border-color: #8a92a5 !important;
}

.border-dark {
  border-color: #1b1f28 !important;
}

.text-gray-100 {
  color: #f8f9fa !important;
}

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #cbd3da !important;
}

.text-gray-200 {
  color: #e9ecef !important;
}

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #bdc6cf !important;
}

.text-gray-300 {
  color: #dee2e6 !important;
}

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #b2bcc5 !important;
}

.text-gray-400 {
  color: #ced4da !important;
}

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #a2aeb9 !important;
}

.text-gray-500 {
  color: #adb5bd !important;
}

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #838f9b !important;
}

.text-gray-600 {
  color: #6c757d !important;
}

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #494f54 !important;
}

.text-gray-700 {
  color: #495057 !important;
}

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #262a2d !important;
}

.text-gray-800 {
  color: #343a40 !important;
}

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #121416 !important;
}

.text-gray-900 {
  color: #212529 !important;
}

a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important;
}

a.text-hover-white:hover, a.text-hover-white:focus {
  color: #fff !important;
}

a.text-hover-primary:hover, a.text-hover-primary:focus {
  color: #4777fa !important;
}

a:hover .text-hover-white, a:focus .text-hover-white {
  color: #fff !important;
}

a:hover .text-hover-primary, a:focus .text-hover-primary {
  color: #4777fa !important;
}

.o-0 {
  opacity: 0 !important;
}

.o-hover-0:hover,
.post:hover .o-hover-0 {
  opacity: 0 !important;
}

.o-1 {
  opacity: 0.1 !important;
}

.o-hover-1:hover,
.post:hover .o-hover-1 {
  opacity: 0.1 !important;
}

.o-2 {
  opacity: 0.2 !important;
}

.o-hover-2:hover,
.post:hover .o-hover-2 {
  opacity: 0.2 !important;
}

.o-3 {
  opacity: 0.3 !important;
}

.o-hover-3:hover,
.post:hover .o-hover-3 {
  opacity: 0.3 !important;
}

.o-4 {
  opacity: 0.4 !important;
}

.o-hover-4:hover,
.post:hover .o-hover-4 {
  opacity: 0.4 !important;
}

.o-5 {
  opacity: 0.5 !important;
}

.o-hover-5:hover,
.post:hover .o-hover-5 {
  opacity: 0.5 !important;
}

.o-6 {
  opacity: 0.6 !important;
}

.o-hover-6:hover,
.post:hover .o-hover-6 {
  opacity: 0.6 !important;
}

.o-7 {
  opacity: 0.7 !important;
}

.o-hover-7:hover,
.post:hover .o-hover-7 {
  opacity: 0.7 !important;
}

.o-8 {
  opacity: 0.8 !important;
}

.o-hover-8:hover,
.post:hover .o-hover-8 {
  opacity: 0.8 !important;
}

.o-9 {
  opacity: 0.9 !important;
}

.o-hover-9:hover,
.post:hover .o-hover-9 {
  opacity: 0.9 !important;
}

.o-10 {
  opacity: 1 !important;
}

.o-hover-10:hover,
.post:hover .o-hover-10 {
  opacity: 1 !important;
}

@media (min-width: 576px) {
  .o-0 {
    opacity: 0 !important;
  }
  .o-hover-0:hover,
  .post:hover .o-hover-0 {
    opacity: 0 !important;
  }
  .o-1 {
    opacity: 0.1 !important;
  }
  .o-hover-1:hover,
  .post:hover .o-hover-1 {
    opacity: 0.1 !important;
  }
  .o-2 {
    opacity: 0.2 !important;
  }
  .o-hover-2:hover,
  .post:hover .o-hover-2 {
    opacity: 0.2 !important;
  }
  .o-3 {
    opacity: 0.3 !important;
  }
  .o-hover-3:hover,
  .post:hover .o-hover-3 {
    opacity: 0.3 !important;
  }
  .o-4 {
    opacity: 0.4 !important;
  }
  .o-hover-4:hover,
  .post:hover .o-hover-4 {
    opacity: 0.4 !important;
  }
  .o-5 {
    opacity: 0.5 !important;
  }
  .o-hover-5:hover,
  .post:hover .o-hover-5 {
    opacity: 0.5 !important;
  }
  .o-6 {
    opacity: 0.6 !important;
  }
  .o-hover-6:hover,
  .post:hover .o-hover-6 {
    opacity: 0.6 !important;
  }
  .o-7 {
    opacity: 0.7 !important;
  }
  .o-hover-7:hover,
  .post:hover .o-hover-7 {
    opacity: 0.7 !important;
  }
  .o-8 {
    opacity: 0.8 !important;
  }
  .o-hover-8:hover,
  .post:hover .o-hover-8 {
    opacity: 0.8 !important;
  }
  .o-9 {
    opacity: 0.9 !important;
  }
  .o-hover-9:hover,
  .post:hover .o-hover-9 {
    opacity: 0.9 !important;
  }
  .o-10 {
    opacity: 1 !important;
  }
  .o-hover-10:hover,
  .post:hover .o-hover-10 {
    opacity: 1 !important;
  }
}

@media (min-width: 768px) {
  .o-0 {
    opacity: 0 !important;
  }
  .o-hover-0:hover,
  .post:hover .o-hover-0 {
    opacity: 0 !important;
  }
  .o-1 {
    opacity: 0.1 !important;
  }
  .o-hover-1:hover,
  .post:hover .o-hover-1 {
    opacity: 0.1 !important;
  }
  .o-2 {
    opacity: 0.2 !important;
  }
  .o-hover-2:hover,
  .post:hover .o-hover-2 {
    opacity: 0.2 !important;
  }
  .o-3 {
    opacity: 0.3 !important;
  }
  .o-hover-3:hover,
  .post:hover .o-hover-3 {
    opacity: 0.3 !important;
  }
  .o-4 {
    opacity: 0.4 !important;
  }
  .o-hover-4:hover,
  .post:hover .o-hover-4 {
    opacity: 0.4 !important;
  }
  .o-5 {
    opacity: 0.5 !important;
  }
  .o-hover-5:hover,
  .post:hover .o-hover-5 {
    opacity: 0.5 !important;
  }
  .o-6 {
    opacity: 0.6 !important;
  }
  .o-hover-6:hover,
  .post:hover .o-hover-6 {
    opacity: 0.6 !important;
  }
  .o-7 {
    opacity: 0.7 !important;
  }
  .o-hover-7:hover,
  .post:hover .o-hover-7 {
    opacity: 0.7 !important;
  }
  .o-8 {
    opacity: 0.8 !important;
  }
  .o-hover-8:hover,
  .post:hover .o-hover-8 {
    opacity: 0.8 !important;
  }
  .o-9 {
    opacity: 0.9 !important;
  }
  .o-hover-9:hover,
  .post:hover .o-hover-9 {
    opacity: 0.9 !important;
  }
  .o-10 {
    opacity: 1 !important;
  }
  .o-hover-10:hover,
  .post:hover .o-hover-10 {
    opacity: 1 !important;
  }
}

@media (min-width: 992px) {
  .o-0 {
    opacity: 0 !important;
  }
  .o-hover-0:hover,
  .post:hover .o-hover-0 {
    opacity: 0 !important;
  }
  .o-1 {
    opacity: 0.1 !important;
  }
  .o-hover-1:hover,
  .post:hover .o-hover-1 {
    opacity: 0.1 !important;
  }
  .o-2 {
    opacity: 0.2 !important;
  }
  .o-hover-2:hover,
  .post:hover .o-hover-2 {
    opacity: 0.2 !important;
  }
  .o-3 {
    opacity: 0.3 !important;
  }
  .o-hover-3:hover,
  .post:hover .o-hover-3 {
    opacity: 0.3 !important;
  }
  .o-4 {
    opacity: 0.4 !important;
  }
  .o-hover-4:hover,
  .post:hover .o-hover-4 {
    opacity: 0.4 !important;
  }
  .o-5 {
    opacity: 0.5 !important;
  }
  .o-hover-5:hover,
  .post:hover .o-hover-5 {
    opacity: 0.5 !important;
  }
  .o-6 {
    opacity: 0.6 !important;
  }
  .o-hover-6:hover,
  .post:hover .o-hover-6 {
    opacity: 0.6 !important;
  }
  .o-7 {
    opacity: 0.7 !important;
  }
  .o-hover-7:hover,
  .post:hover .o-hover-7 {
    opacity: 0.7 !important;
  }
  .o-8 {
    opacity: 0.8 !important;
  }
  .o-hover-8:hover,
  .post:hover .o-hover-8 {
    opacity: 0.8 !important;
  }
  .o-9 {
    opacity: 0.9 !important;
  }
  .o-hover-9:hover,
  .post:hover .o-hover-9 {
    opacity: 0.9 !important;
  }
  .o-10 {
    opacity: 1 !important;
  }
  .o-hover-10:hover,
  .post:hover .o-hover-10 {
    opacity: 1 !important;
  }
}

@media (min-width: 1200px) {
  .o-0 {
    opacity: 0 !important;
  }
  .o-hover-0:hover,
  .post:hover .o-hover-0 {
    opacity: 0 !important;
  }
  .o-1 {
    opacity: 0.1 !important;
  }
  .o-hover-1:hover,
  .post:hover .o-hover-1 {
    opacity: 0.1 !important;
  }
  .o-2 {
    opacity: 0.2 !important;
  }
  .o-hover-2:hover,
  .post:hover .o-hover-2 {
    opacity: 0.2 !important;
  }
  .o-3 {
    opacity: 0.3 !important;
  }
  .o-hover-3:hover,
  .post:hover .o-hover-3 {
    opacity: 0.3 !important;
  }
  .o-4 {
    opacity: 0.4 !important;
  }
  .o-hover-4:hover,
  .post:hover .o-hover-4 {
    opacity: 0.4 !important;
  }
  .o-5 {
    opacity: 0.5 !important;
  }
  .o-hover-5:hover,
  .post:hover .o-hover-5 {
    opacity: 0.5 !important;
  }
  .o-6 {
    opacity: 0.6 !important;
  }
  .o-hover-6:hover,
  .post:hover .o-hover-6 {
    opacity: 0.6 !important;
  }
  .o-7 {
    opacity: 0.7 !important;
  }
  .o-hover-7:hover,
  .post:hover .o-hover-7 {
    opacity: 0.7 !important;
  }
  .o-8 {
    opacity: 0.8 !important;
  }
  .o-hover-8:hover,
  .post:hover .o-hover-8 {
    opacity: 0.8 !important;
  }
  .o-9 {
    opacity: 0.9 !important;
  }
  .o-hover-9:hover,
  .post:hover .o-hover-9 {
    opacity: 0.9 !important;
  }
  .o-10 {
    opacity: 1 !important;
  }
  .o-hover-10:hover,
  .post:hover .o-hover-10 {
    opacity: 1 !important;
  }
}

.widget-categories ul {
  padding: 0;
  margin-bottom: .5rem;
  list-style-type: none;
}

.widget-categories li {
  padding-left: 1.2rem;
}

.widget-categories li::before {
  position: absolute;
  display: block;
  margin-left: -.8rem;
  font-family: "Font Awesome 5 Free", serif;
  font-style: normal;
  font-weight: 900;
  content: "";
  opacity: .6;
}

.widget-categories li li {
  margin-top: .3rem;
}

.widget-categories > ul {
  display: flex;
  flex-wrap: wrap;
}

.widget-categories > ul > li {
  width: 50%;
  padding-right: 1.2rem;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .widget-categories > ul > li {
    width: 33.33%;
  }
}

@media (min-width: 768px) {
  .widget-categories > ul > li {
    width: 25%;
  }
}

@media (min-width: 992px) {
  .widget-categories > ul > li {
    width: 20%;
  }
}

@media (min-width: 1200px) {
  .widget-categories > ul > li {
    width: 100%;
    padding-right: 0;
    margin-bottom: 0;
  }
}

/*# sourceMappingURL=publicize.css.map */